import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import {Link} from "react-router-dom";
import DropdownMenu from "../../ui/element/DropdownMenu";
import {Student} from "../../../model/tutor/Student";

interface BoardPreviewProps {
    student: Student;
    setStudentToEdit: React.Dispatch<React.SetStateAction<Student | null>>;
    setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStudentToShare: React.Dispatch<React.SetStateAction<Student | null>>;
    setShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStudentToDelete: React.Dispatch<React.SetStateAction<Student | null>>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setStudentToToggleArchive: React.Dispatch<React.SetStateAction<Student | null>>;
    setToggleArchiveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isActive: boolean;
    guestSession: boolean;
}

export default function StudentPreview(props: BoardPreviewProps) {
    const {
        student,
        setStudentToEdit,
        setEditModalOpen,
        setStudentToShare,
        setShareModalOpen,
        setStudentToDelete,
        setDeleteModalOpen,
        setStudentToToggleArchive,
        setToggleArchiveModalOpen,
        isActive,
        guestSession
    } = props;

    const studentLink = guestSession ? `/demo/uczen/${student.id}` : `/uczen/${student.id}`;
    const initials = `${student.name.charAt(0).toUpperCase()}${
        student.lastName ? student.lastName.charAt(0).toUpperCase() : ""
    }`;

    return (
        <div className="col-12 col-sm-6 col-md-4">
            <div className=' border border-secondary p-4 rounded mb-3'>
                {/* Header Section with Initials and Name */}
                <div className="d-flex align-items-center">
                    <Link to={studentLink} className="text-decoration-none">
                        <div
                            className="d-flex align-items-center justify-content-center rounded-circle me-3 text-white fw-medium"
                            style={{
                                width: "80px",
                                height: "80px",
                                border: "2px solid white",
                                fontSize: "2rem",
                                textTransform: "uppercase",
                            }}
                        >
                            {initials}
                        </div>
                    </Link>
                    <AnimatedLink
                        to={studentLink}
                        className="d-inline-block text-decoration-none"
                    >
                        <h3 className="font-heading mb-0 fs-8 link-white text-white">
                            {student.name} {student.lastName}
                        </h3>
                    </AnimatedLink>
                </div>

                {/* Description */}
                <p className="mt-3 mb-2 text-secondary-light fs-10 fw-medium">
                    {student.description}
                </p>
                <p className="mb-2 text-secondary-light fw-medium fs-12">
                    Ostatnia aktywność: {student.lastBoardOpenedAt.split("T")[0]}
                </p>

                {/* Footer with Link and Dropdown Menu */}
                <div className="d-flex align-items-center justify-content-between">
                    <AnimatedLink
                        className="d-flex align-items-center text-success link-success"
                        to={studentLink}
                    >
                        <span className="me-2 fw-semibold fs-10">Zobacz więcej</span>
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.9467 7.74665C11.9149 7.66481 11.8674 7.59005 11.8067 7.52665L8.47333 4.19331C8.41117 4.13116 8.33738 4.08185 8.25617 4.04821C8.17495 4.01457 8.08791 3.99725 8 3.99725C7.82246 3.99725 7.6522 4.06778 7.52667 4.19331C7.46451 4.25547 7.4152 4.32927 7.38156 4.41048C7.34792 4.4917 7.33061 4.57874 7.33061 4.66665C7.33061 4.84418 7.40113 5.01445 7.52667 5.13998L9.72667 7.33331H4.66667C4.48986 7.33331 4.32029 7.40355 4.19526 7.52858C4.07024 7.6536 4 7.82317 4 7.99998C4 8.17679 4.07024 8.34636 4.19526 8.47139C4.32029 8.59641 4.48986 8.66665 4.66667 8.66665H9.72667L7.52667 10.86C7.46418 10.922 7.41458 10.9957 7.38074 11.0769C7.34689 11.1582 7.32947 11.2453 7.32947 11.3333C7.32947 11.4213 7.34689 11.5085 7.38074 11.5897C7.41458 11.6709 7.46418 11.7447 7.52667 11.8066C7.58864 11.8691 7.66238 11.9187 7.74361 11.9526C7.82485 11.9864 7.91199 12.0038 8 12.0038C8.08801 12.0038 8.17514 11.9864 8.25638 11.9526C8.33762 11.9187 8.41136 11.8691 8.47333 11.8066L11.8067 8.47331C11.8674 8.40991 11.9149 8.33515 11.9467 8.25331C12.0133 8.09101 12.0133 7.90895 11.9467 7.74665Z"
                                fill="currentColor"
                            />
                        </svg>
                    </AnimatedLink>
                    <DropdownMenu
                        elements={[
                            {
                                name: "Edytuj",
                                onClick: () => {
                                    setStudentToEdit(student);
                                    setEditModalOpen(true);
                                },
                            },
                            {
                                name: "Udostępnij",
                                onClick: () => {
                                    setStudentToShare(student);
                                    setShareModalOpen(true);
                                },
                            },
                            {
                                name: isActive ? "Archiwizuj" : "Przywróć",
                                onClick: () => {
                                    setStudentToToggleArchive(student);
                                    setToggleArchiveModalOpen(true);
                                }
                            },
                            {
                                name: "Usuń",
                                onClick: () => {
                                    setStudentToDelete(student);
                                    setDeleteModalOpen(true);
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}
// EnhancedOptIn.jsx
import Heading from "../../ui/Heading";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AdvantageItem from "../../ui/element/item/AdvantageItem";
import {useSearchParams} from "react-router-dom";
import React from "react";

export default function OptInV2() {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') ?? 'optin';
    const fbclid = searchParams.get('fbclid');

    let redirectUrl = `/demo?source=${source}`;
    if (fbclid) {
        redirectUrl += `&fbclid=${fbclid}`;
    }

    return <>
        {/* Header (Mobile) */}
        <div className="d-block d-md-none">
            <div className="bg-dark-subtle p-3 rounded-3 mb-0">
                <p className="text-white text-center fs-12 mb-0">
                    Przetestuj w <span className="badge bg-success text-white pe-2 ps-2">100% bezpłatnie</span> już teraz.
                </p>
            </div>
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto"
                title={"Tablica stworzona specjalnie dla"}
                customTitleStyles="font-heading mb-1 fs-4 text-white"
            />
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                title={"KOREPETYTORÓW"}
                customTitleStyles="font-heading mb-1 fs-3 text-warning"
            />
            <p className="text-warning fs-10 fw-medium text-center mx-1">
                <i>Prowadź zajęcia wygodniej. Twoi uczniowie docenią profesjonalizm.</i>
            </p>
        </div>

        {/* Header (Desktop) */}
        <div className="d-none d-md-block">
            <div className="bg-dark-subtle p-3 rounded-3 mb-4 mx-auto" style={{maxWidth: "600px"}}>
                <p className="text-white text-center fs-11 mb-0">
                    Przetestuj w <span className="badge bg-success text-white pe-2 ps-2">100% bezpłatnie</span> już teraz.
                </p>
            </div>
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto"
                title={"Tablica stworzona specjalnie dla"}
                customTitleStyles="font-heading mb-1 fs-5 text-white"
            />
            <Heading
                customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                title={"KOREPETYTORÓW"}
                customTitleStyles="font-heading mb-1 fs-3 text-warning"
            />
            <p className="text-warning fs-10 fw-medium text-center">
                <i>Prowadź zajęcia wygodniej. Twoi uczniowie docenią profesjonalizm.</i>
            </p>
        </div>

        {/* CTA */}
        <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
            <AnimatedLink
                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow-lg"
                to={redirectUrl}
            >
                PRZETESTUJ JUŻ TERAZ
            </AnimatedLink>
            <p className="text-white-50 mt-2 fs-11">Bez logowania, bez podawania karty, bez ukrytych opłat</p>
        </div>

        <div className='row mt-8'>
            <div className='col-md-6 col-12 text-center'>
                <div className="position-relative" style={{width: '100%', paddingTop: '62.25%'}}>
                    <img
                        src='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/funnel/optin-tnk-showcase.png'
                        className='img-fluid shadow-lg rounded-3'
                        alt='profesjonalna tablica online dla korepetytorów'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className='col-md-6 col-12 mt-4 mt-md-2'>
                <div className='container'>
                    <Heading customTitleStyles='font-heading mb-4 fs-5 text-white'
                             customStyles='mb-6 text-md-left mw-md-4xl mx-auto' title='Dlaczego korepetytorzy wybierają naszą Tablicę?'/>
                    <AdvantageItem
                        title='Różne tła, kratki i opcje rysowania - wszystko czego potrzebuje nauczyciel'/>
                    <AdvantageItem
                        titleHtml={<span>Przejrzysta organizacja - <b>twórz uczniów i przypisuj im tablice</b></span>}/>
                    <AdvantageItem
                        title={`Brak logowania dla uczniów - udostępniasz link jednym kliknięciem`}/>
                    <AdvantageItem
                        title='Nieograniczona liczba stron w każdej tablicy'/>
                    <AdvantageItem
                        title='Eksportuj całość do PDF jednym kliknięciem'/>
                    <AdvantageItem
                        title='Gotowe kształty geometryczne, a stale dodajemy nowe funkcje'/>

                    <div className='text-center mw-md-2xl mx-auto mt-6 mb-6'>
                        <AnimatedLink
                            className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow-lg"
                            to={redirectUrl}
                        >
                            WYPRÓBUJ ZA DARMO
                        </AnimatedLink>
                    </div>
                </div>
            </div>
        </div>

        {/* Testimonials Section */}
        <div className="mt-10">
            <Heading
                customStyles="mb-5 text-center"
                title={"Co mówią inni nauczyciele?"}
                customTitleStyles="font-heading fs-4 text-white"
            />

            <div className="row justify-content-center">
                <div className="col-md-4 col-12 mb-4">
                    <div className="bg-dark-subtle p-4 rounded-3 h-100">
                        <div className="d-flex align-items-center mb-2">
                            <div className="text-warning me-2">★★★★★</div>
                            <div className="text-white">Ocena: 10/10</div>
                        </div>
                        <p className="text-white mb-2">
                            <i>"Wszystkie funkcje których potrzebuje, pisanie, różne rozmiary pisaków, kratek, tablica nie zacina się."</i>
                        </p>
                        <p className="text-warning mb-0 fw-medium">- Nauczyciel matematyki</p>
                    </div>
                </div>
                <div className="col-md-4 col-12 mb-4">
                    <div className="bg-dark-subtle p-4 rounded-3 h-100">
                        <div className="d-flex align-items-center mb-2">
                            <div className="text-warning me-2">★★★★★</div>
                            <div className="text-white">Ocena: 9/10</div>
                        </div>
                        <p className="text-white mb-2">
                            <i>"Prostota i wygoda. Łatwość edycji tego co już napisałem. To naprawdę dobra tablica w przystępnej cenie."</i>
                        </p>
                        <p className="text-warning mb-0 fw-medium">- Doświadczony korepetytor</p>
                    </div>
                </div>
                <div className="col-md-4 col-12 mb-4">
                    <div className="bg-dark-subtle p-4 rounded-3 h-100">
                        <div className="d-flex align-items-center mb-2">
                            <div className="text-warning me-2">★★★★★</div>
                            <div className="text-white">Ocena: 9/10</div>
                        </div>
                        <p className="text-white mb-2">
                            <i>"Brak konieczności logowania ucznia. Prostota działania, brak konieczności instalacji osobnej aplikacji."</i>
                        </p>
                        <p className="text-warning mb-0 fw-medium">- Korepetytor fizyki</p>
                    </div>
                </div>
            </div>

            {/* Final CTA */}
            <div className='text-center mw-md-3xl mx-auto mt-7'>
                <p className="text-white mb-3">Dołącz do setek zadowolonych nauczycieli, którzy usprawniają swoje zajęcia</p>
                <AnimatedLink
                    className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow-lg"
                    to={redirectUrl}
                >
                    PRZETESTUJ JUŻ TERAZ
                </AnimatedLink>
                <p className="text-white-50 mt-2 fs-11">W bezpłatnej wersji możesz tworzyć aż 15 tablic miesięcznie!</p>
            </div>
        </div>
    </>
}
import { useEffect } from 'react';

/**
 * Custom hook to handle window resizing and prevent scrolling
 * Sets a CSS variable for window inner height and manages scroll behavior
 * @returns handleResize function that can be called to manually trigger resize calculations
 */
export const useResize = () => {
    // Function to update CSS variable based on window height
    const handleResize = () => {
        const windowInnerHeight = window.innerHeight;
        document.documentElement.style.setProperty('--windowInnerHeight', `${windowInnerHeight}px`);
    };

    useEffect(() => {
        // Initial resize calculation
        handleResize();

        // Add listeners for resize and orientation change
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        // Prevent scrolling and ensure full height usage
        const preventScroll = () => {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
            document.documentElement.style.overflow = 'hidden';
            document.documentElement.style.height = '100%';
        };

        preventScroll();

        // Cleanup function
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
            // Reset styles on unmount
            document.body.style.overflow = '';
            document.body.style.height = '';
            document.documentElement.style.overflow = '';
            document.documentElement.style.height = '';
        };
    }, []);

    return { handleResize };
};
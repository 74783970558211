import 'tldraw/tldraw.css'
import React from "react";
import MainDesign from "../../../components/ui/MainDesign";
import Meta from "../../../components/common/Meta";
import {useParams} from "react-router-dom";
import {DemoService} from "../../../service/DemoService";
import {useResize} from "../../../hooks/useResize";
import GuestWhiteboard from "../../../components/guest/GuestWhiteboard";
import ErrorPage from "../../ErrorPage";

export default function DemoWhiteboardPage() {
    const { handleResize } = useResize();

    const {roomId} = useParams();

    const board = DemoService.getBoardById(roomId!!);

    if (!board) {
        return <ErrorPage/>;
    }

    return (
        <>
            <MainDesign minHeight="100vh">
                <Meta
                    title={board.title}
                    description={board.description}
                    imageUrl={board.previewUrl}
                />
                <div
                    style={{
                        height: 'var(--windowInnerHeight, 100vh)',
                        overflow: 'hidden', // Ensure no scrolling within the board container
                    }}
                >

                        <GuestWhiteboard boardResponse={{
                            board: board,
                            isSubscriptionExpired: false,
                            archivedOrDeleted: false,
                            readonlySnapshot: undefined,
                            isTutor: true,
                            userSettings: {
                                brushSizes: {
                                    s: 1.2,
                                    m: 2,
                                    l: 3.5,
                                    xl: 5
                                },
                                gridMode: 0,
                                theme: 'dark'
                            }
                        }} handleResize={handleResize}/>
                </div>
            </MainDesign>
        </>
    );
}
import RawModal from "../../ui/modal/RawModal";
import React, {useEffect, useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useToast} from "../../ui/toast/ToastContext";
import {Board} from "../../../model/tutor/Board";
import {BoardService} from "../../../service/BoardService";
import {Student} from "../../../model/tutor/Student";
import {TutorService} from "../../../service/TutorService";
import FormInput from "../../ui/form/FormInput";
import FormTextArea from "../../ui/form/FormTextArea";
import {useNavigate} from "react-router-dom";

interface BoardDuplicateModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    board: Board
    setBoardToDuplicate?: React.Dispatch<React.SetStateAction<Board | null>>
    onDuplicateSuccess?: (board: Board) => void
    guestSession: boolean
}

export default function BoardDuplicateModal(props: BoardDuplicateModalProps) {
    const {isOpen, setOpen, board, setBoardToDuplicate, onDuplicateSuccess, guestSession} = props;
    const {showMessage} = useToast();
    const navigate = useNavigate();

    const [students, setStudents] = useState<Student[]>([]);
    const [selectedStudentId, setSelectedStudentId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showTitleEditor, setShowTitleEditor] = useState<boolean>(false);

    // Title and description fields for editing
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        if (isOpen && !guestSession) {
            loadStudents();
            // Initialize the form with the board's title and description
            setTitle(`${board?.title || ""}`);
            setDescription(board?.description || "");
        }
    }, [isOpen, board]);

    async function loadStudents() {
        try {
            const studentsList = await TutorService.getAllStudents();
            setStudents(studentsList.filter(student => !student.isArchived));
            // Reset selection when loading new students
            setSelectedStudentId("");
            setShowTitleEditor(false);
        } catch (error) {
            console.error("Error loading students:", error);
            showMessage("Nie udało się załadować listy uczniów.");
        }
    }

    function handleStudentSelect(studentId: string) {
        setSelectedStudentId(studentId);
        if (studentId) {
            setShowTitleEditor(true);
        } else {
            setShowTitleEditor(false);
        }
    }

    async function handleDuplicate() {
        if (!selectedStudentId) {
            showMessage("Wybierz ucznia dla którego chcesz zduplikować tablicę.");
            return;
        }

        setIsLoading(true);
        try {
            const duplicatedBoard = await BoardService.duplicateBoard(
                board.id,
                selectedStudentId,
                title,
                description
            );

            showMessage("Tablica została zduplikowana pomyślnie.");
            if (onDuplicateSuccess) {
                onDuplicateSuccess(duplicatedBoard);
            }

            // Navigate to the new board
            navigate(`/t/${duplicatedBoard.id}`);

            handleClose();
        } catch (error) {
            console.error("Error duplicating board:", error);
            showMessage("Nie udało się zduplikować tablicy.");
            setIsLoading(false);
        }
    }

    function handleClose() {
        setOpen(false);
        if (setBoardToDuplicate) {
            setBoardToDuplicate(null);
        }
        setSelectedStudentId("");
        setShowTitleEditor(false);
        setTitle("");
        setDescription("");
    }

    return (
        <RawModal
            isOpen={isOpen}
            setOpen={setOpen}
            title={'Duplikuj tablicę'}
            onClose={handleClose}
            children={
                <>
                    <p className="text-white fw-medium mb-4">
                        Wybierz ucznia, dla którego chcesz zduplikować tablicę <i>{board?.title}</i>
                    </p>
                    {
                        guestSession && <p className="fw-medium text-warning mb-4">
                            W trybie testowym duplikowanie tablic nie jest możliwe. Zarejestruj się, aby uzyskać dostęp
                            do wszystkich funkcji.
                        </p>
                    }

                    <div className="mb-4">
                        <label htmlFor="studentSelect" className="form-label text-white">Uczeń:</label>
                        <select
                            id="studentSelect"
                            className={`form-select bg-dark ${guestSession ? 'text-secondary' : 'text-white'}`}
                            value={selectedStudentId}
                            disabled={guestSession}
                            onChange={(e) => handleStudentSelect(e.target.value)}
                        >
                            <option value="">Wybierz ucznia...</option>
                            {students.map(student => (
                                <option key={student.id} value={student.id}>
                                    {student.name} {student.lastName || ''}
                                </option>
                            ))}
                        </select>
                    </div>

                    {showTitleEditor && (
                        <div className="board-editor-section mt-4">
                            <h5 className="text-white mb-3">Edytuj dane tablicy</h5>

                            <div className="mb-3">
                                <FormInput
                                    label="Nazwa tablicy:"
                                    placeholder="Podaj nazwę..."
                                    value={title}
                                    onChange={setTitle}
                                />
                            </div>

                            <div className="mb-3">
                                <FormTextArea
                                    label="Opis tablicy:"
                                    placeholder="Podaj opis..."
                                    value={description}
                                    onChange={setDescription}
                                    rows={3}
                                />
                            </div>
                        </div>
                    )}

                    <div className="d-flex justify-content-end gap-2 mt-4">
                        <AnimatedButton
                            type='button'
                            className="btn btn-outline-secondary text-white"
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            Anuluj
                        </AnimatedButton>
                        <AnimatedButton
                            className="btn btn-success"
                            onClick={handleDuplicate}
                            disabled={!selectedStudentId || isLoading || guestSession}
                        >
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status"
                                          aria-hidden="true"></span>
                                    Duplikowanie...
                                </>
                            ) : 'Duplikuj'}
                        </AnimatedButton>
                    </div>
                </>
            }
        />
    );
}
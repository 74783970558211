import RawModal from "../../ui/modal/RawModal";
import { Student } from "../../../model/tutor/Student";
import React, { useState } from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import { useToast } from "../../ui/toast/ToastContext";
import CopyToClipIcon from "../../ui/element/CopyToClipIcon";
import { TutorService } from "../../../service/TutorService";

interface StudentShareModalProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    student: Student;
    setStudentToShare: React.Dispatch<React.SetStateAction<Student | null>>;
    guestSession: boolean;
}

export default function StudentShareModal(props: StudentShareModalProps) {
    const { isOpen, setOpen, student, setStudentToShare, guestSession } = props;

    const { showMessage } = useToast();

    const shareLink = `${window.location.origin}/uczen/${student?.id}`;

    const [isSharingAllowed, setIsSharingAllowed] = useState<boolean | null>(
        null
    );

    async function copyToClip() {
        await navigator.clipboard.writeText(shareLink);
        showMessage("Pomyślnie skopiowano.");
    }

    async function changeBoardSharingAllowed(newValue: boolean) {
        await TutorService.changeBoardSharingAllowed(student?.id, newValue);
        setIsSharingAllowed(newValue);
    }

    function handleSharingToggle(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.checked;
        changeBoardSharingAllowed(newValue);
    }

    return (
        <>
            <RawModal
                isOpen={isOpen}
                setOpen={setOpen}
                title={"Udostępnij wszystkie tablice"}
                onClose={() => setStudentToShare(null)}
                children={
                    <>
                        {
                            guestSession && <>
                            <p className='fw-medium text-warning'>W trybie gościa udostępnianie wszystkich tablic dla ucznia nie jest możliwe. Zarejestruj się, aby uzyskać dostęp do wszystkich funkcji.</p>
                            </>
                        }
                        {
                            !guestSession && <>
                                {
                                    (isSharingAllowed === null ? student?.isBoardSharingAllowed : isSharingAllowed) && <>
                                        <p className="text-white fw-medium text-secondary-light">
                                            Uwaga! Każda osoba z poniższym linkiem będzie miała dostęp do
                                            wyświetlania wszystkich tablic z uczniem{" "}
                                            <i>
                                                {student?.name} {student?.lastName}
                                            </i>
                                            .
                                        </p>
                                    </>
                                }
                                <div className="row justify-content-center g-2 mt-2">
                                    {
                                        (isSharingAllowed === null ? student?.isBoardSharingAllowed : isSharingAllowed) && <>
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-md flex-grow-1 bg-dark"
                                                        value={shareLink}
                                                        readOnly
                                                        style={{
                                                            padding: "1rem 0.75rem",
                                                            color: "white"
                                                        }} // Custom padding for smaller input
                                                    />
                                                    <AnimatedButton
                                                        className="btn btn-outline-light btn-sm ms-2 flex-grow-0"
                                                        type="button"
                                                        onClick={copyToClip}
                                                        style={{whiteSpace: "nowrap"}} // Ensure text stays in one line
                                                    >
                                                        <CopyToClipIcon/>
                                                        <span>Skopiuj link</span>
                                                    </AnimatedButton>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center align-items-center mt-4 text-white">
                                            <span className="me-5 fs-9">Udostępnianie wyłączone</span>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="boardSharingSwitch"
                                                    checked={isSharingAllowed === null ? student?.isBoardSharingAllowed : isSharingAllowed}
                                                    onChange={handleSharingToggle}
                                                    style={{transform: "scale(1.5)", cursor: "pointer"}}
                                                />
                                                <label
                                                    className="form-check-label visually-hidden"
                                                    htmlFor="boardSharingSwitch"
                                                >
                                                    Board Sharing Toggle
                                                </label>
                                            </div>
                                            <span className="ms-3 fs-9">Udostępnianie włączone</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            />
        </>
    );
}
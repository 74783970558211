// OptInPage.jsx
import MainDesign from "../../../components/ui/MainDesign";
import Meta from "../../../components/common/Meta";
import FooterCopyright from "../../../components/common/footer/FooterCopyright";
import React from "react";
import OptInV2 from "../../../components/guest/funnel/OptInV2";

export default function OptInV2Page() {
    return <>
        <Meta
            title="Tablica dla korepetytorów - Przetestuj za darmo"
            description="Profesjonalna tablica dla korepetytorów z łatwym udostępnianiem dla uczniów bez potrzeby logowania. Wielostronicowa, z gotowymi kształtami i eksportem do PDF."
            imageUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/funnel/optin-tnk-showcase.png'
        />
        <MainDesign containerStyles="container pt-6 pt-md-12 pb-10" minHeight='100vh'>
            <OptInV2/>
            <div className='mt-24'/>
            <FooterCopyright/>
        </MainDesign>
    </>
}
import {DefaultQuickActions, DefaultQuickActionsContent, Editor, TldrawUiMenuItem} from "tldraw";
import React from "react";

export function CustomQuickActions(
    editor: Editor,
    setShareBoardModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setBoardInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSettingsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    toggleGridMode: () => void,
    gridMode: number,
    isTutor: boolean
) {
    return (
        <>
            <DefaultQuickActions>
                {/*@ts-ignore*/}
                <DefaultQuickActionsContent/>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="share-1" onSelect={() => setShareBoardModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="info-circle" onSelect={() => setBoardInfoModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem
                        id="code"
                        icon={"fill-pattern"}
                        onSelect={toggleGridMode}
                        label={gridMode === 0 ? "Siatka: Brak" : gridMode === 1 ? "Siatka: Standardowa" : "Siatka: Mała"}
                    />
                </div>
                {
                    isTutor && <div style={{cursor: 'pointer'}}>
                        <TldrawUiMenuItem
                            id="code"
                            icon={"dots-vertical"}
                            onSelect={() => setSettingsModalOpen(true)}
                            label={"Ustawienia"}
                        />
                    </div>
                }

            </DefaultQuickActions>
        </>
    )
}
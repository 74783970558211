import {
    DefaultStylePanel,
    DefaultStylePanelContent,
    TldrawUiButton,
    TldrawUiButtonLabel,
    TLUiStylePanelProps,
    useRelevantStyles
} from "tldraw";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function CustomStylePanel(props: TLUiStylePanelProps, guestSession?: boolean) {
    const [hidePanel, setHidePanel] = useState(false);

    const navigate = useNavigate()

    const styles = useRelevantStyles()

    return (
        <DefaultStylePanel {...props}>
            <div style={{backgroundColor: 'dark'}}>
                {
                    guestSession && <div style={{backgroundColor: '#f59e0c'}}>
                        <TldrawUiButton
                            type="menu"
                            onClick={() => {
                                navigate('/rejestracja')
                            }}
                        >
                            <TldrawUiButtonLabel>Zarejestruj się</TldrawUiButtonLabel>
                        </TldrawUiButton>
                    </div>
                }

                <TldrawUiButton
                    type="menu"
                    onClick={() => {
                        setHidePanel((prev) => !prev)
                    }}
                >
                    <TldrawUiButtonLabel>{hidePanel ? 'Pokaż menu' : 'Ukryj menu'}</TldrawUiButtonLabel>
                </TldrawUiButton>
            </div>
            {
                !hidePanel && <DefaultStylePanelContent styles={styles}/>
            }

        </DefaultStylePanel>
    )
}
import { Link } from "react-router-dom";
import AnimatedButton from "../motion/AnimatedButton";
import React, { ReactNode } from "react";
import useModalClose from "../../../hooks/useModalHook";

interface FormModalProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    title: string;
    form: ReactNode;
    belowForm?: ReactNode;
    belowFormCondition?: boolean;
    isSubmitting: boolean;
    onSubmit: () => void;
    onClose?: () => void;
}

export default function FormModal(props: FormModalProps) {
    const {
        isOpen,
        setOpen,
        title,
        form,
        belowForm,
        belowFormCondition,
        isSubmitting,
        onSubmit,
        onClose,
    } = props;

    const modalRef = useModalClose(isOpen, setOpen, onClose);

    return (
        <section
            className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${
                isOpen ? "" : "d-none"
            }`}
            style={{ zIndex: 50 }}
        >
            <div className="d-flex flex-wrap align-items-center h-100">
                <div className="container py-10">
                    <form
                        ref={modalRef}
                        className="p-10 mw-3xl mx-auto bg-light-dark overflow-hidden rounded shadow"
                    >
                        <div className="mb-8 row g-8 align-items-center">
                            <div className="col-auto">
                                <Link
                                    className="navbar-close text-secondary-light"
                                    to="#"
                                    onClick={() => {
                                        setOpen(false);
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 18L18 6M6 6L18 18"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </div>
                            <div className="col-11">
                                <span className="fs-7 fw-bold text-white">{title}</span>
                            </div>

                            {form}
                        </div>
                        {belowFormCondition && belowForm}

                        <div className="row mt-12">
                            <div className="col-12 col-md-4">
                                <AnimatedButton
                                    className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                    type="button"
                                    onClick={() => {
                                        setOpen(false);
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                    disabled={isSubmitting}
                                >
                                    Anuluj
                                </AnimatedButton>
                            </div>
                            <div className="col-12 col-md-8 pt-2 pt-md-0">
                                <AnimatedButton
                                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                                    onClick={onSubmit}
                                    type="button"
                                    disabled={isSubmitting}
                                >
                                    Potwierdź
                                </AnimatedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
import {HttpMethod, HttpService} from "./HttpService";
import {CancelNewsletterRequest, User, UserSettings} from "../model/tutor/User";

export class UserService {
    public static async getUser(): Promise<User> {
        return HttpService.sendRequest<User>(
            HttpMethod.GET,
            `/user`,
        );
    }

    public static async cancelNewsletter(request: CancelNewsletterRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            `/cancel-newsletter`,
            JSON.stringify(request),
        );
    }
    
    public static async updateUserSettings(settings: UserSettings): Promise<{message: string, settings: UserSettings}> {
        return HttpService.sendRequest<{message: string, settings: UserSettings}>(
            HttpMethod.PUT,
            `/user/settings`,
            JSON.stringify(settings),
        );
    }
    
    public static async getDefaultUserSettings(): Promise<UserSettings> {
        return HttpService.sendRequest<UserSettings>(
            HttpMethod.GET,
            `/user/settings/default`,
        );
    }
}
import React, { useState, useEffect } from 'react';
import Meta from "../../../components/common/Meta";
import MainDesign from "../../../components/ui/MainDesign";
import TutorIndexStudentsSection from "../../../components/tutor/student/TutorIndexStudentsSection";
import {DemoService} from "../../../service/DemoService";
import {redirectIfLoggedIn} from "../../../util/AuthUtil";
import { Student } from '../../../model/tutor/Student';

// Custom event for student updates
export const STUDENT_UPDATED_EVENT = 'student-updated';

export default function DemoPage() {
    // Use state to hold students data
    const [students, setStudents] = useState<Student[]>([]);

    // Function to refresh students data
    const refreshStudents = () => {
        setStudents(DemoService.getStudents());
    };

    // Initial data load
    useEffect(() => {
        refreshStudents();

        // Listen for the custom student-updated event
        window.addEventListener(STUDENT_UPDATED_EVENT, refreshStudents);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener(STUDENT_UPDATED_EVENT, refreshStudents);
        };
    }, []);

    return (
        <React.Fragment>
            <Meta title='TablicaNaKorki.PL - Demo'/>
            <MainDesign containerStyles="pt-10 pb-24 container">
                <div className="text-center mb-4">
                                        <span
                                            className={`badge text-white bg-warning text-uppercase shadow`}>
                                            Tryb DEMO
                                        </span>
                </div>

                <h1 className={`text-white text-center mt-6`}>
                    Dzień dobry!
                </h1>
                <p className='text-center mw-md-3xl mx-auto fw-medium text-warning mb-8'>Znajdujesz się obecnie w trybie demonstracyjnym. Twoje zmiany nie zostaną zapisane. Możesz zobaczyć, w jaki sposób działa Tablica i zdecydować, czy chcesz z niej korzystać, czy nie.</p>
                <p className='text-center mw-md-4xl mx-auto fw-bold text-white mb-8'>Aby korzystać ze wszystkich funkcji, a także zapisywać swoich uczniów oraz tablice, <a href='/rejestracja' className='text-success text-decoration-underline'>zarejestruj się już teraz.</a></p>
                <TutorIndexStudentsSection
                    students={students}
                    canAddNewStudent={true}
                    guestSession={true}
                />
            </MainDesign>
        </React.Fragment>
    );
}

export async function loader({request}: { request: Request }) {
    const redirect = await redirectIfLoggedIn();

    if (redirect) {
        return redirect;
    }

    const source = new URL(request.url).searchParams.get('source') ?? "unknown";
    const fbclid = new URL(request.url).searchParams.get('fbclid')
    DemoService.storeInitialSource(source);
    if (fbclid) {
        DemoService.storeInitialFbclid(fbclid);
    }
    DemoService.createInitialData();

    return null;
}
import {Link, useLocation} from "react-router-dom";
import React, { ReactNode } from "react";
import AnimatedLink from "../motion/AnimatedLink";
import useModalClose from "../../../hooks/useModalHook";

interface FormModalProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    title: string;
    children: ReactNode;
    onClose?: () => void;
}

export default function RawModal(props: FormModalProps) {
    const { isOpen, setOpen, title, children, onClose } = props;

    const modalRef = useModalClose(isOpen, setOpen, onClose);

    const location = useLocation();
    const currentPathWithSearch = `${location.pathname}${location.search}`;

    return (
        <section
            className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${
                isOpen ? "" : "d-none"
            }`}
            style={{ zIndex: 50 }}
        >
            <div className="d-flex flex-wrap align-items-center h-100">
                <div className="container py-10">
                    <form
                        ref={modalRef}
                        className="p-10 mw-3xl mx-auto bg-light-dark overflow-hidden rounded shadow"
                    >
                        <div className="mb-4 d-flex justify-content-between align-items-center">
                            <div>
                                <AnimatedLink
                                    className="navbar-close text-secondary-light"
                                    to={currentPathWithSearch}
                                    onClick={() => {
                                        setOpen(false);
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 18L18 6M6 6L18 18"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </AnimatedLink>
                            </div>
                            <div className="flex-grow-1 px-3">
                                <span className="fs-7 fw-bold text-white">{title}</span>
                            </div>
                        </div>

                        {children}
                    </form>
                </div>
            </div>
        </section>
    );
}
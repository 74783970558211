import React from "react";
import {useParams} from "react-router-dom";
import MainDesign from "../../../components/ui/MainDesign";
import Meta from "../../../components/common/Meta";
import StudentBoards from "../../../components/tutor/student/StudentBoards";
import {DemoService} from "../../../service/DemoService";
import ErrorPage from "../../ErrorPage";

export default function DemoStudentPage() {
    const {studentId} = useParams();

    const student = DemoService.getStudentById(studentId!!)

    if (!student) {
        return <ErrorPage/>;
    }

    return <>
        <MainDesign containerStyles='container py-16'>
            <Meta title='Tablice z uczniem'/>
                <StudentBoards boards={DemoService.getBoardsForStudent(studentId!!)} student={student}
                               readonly={false} guestSession={true} />
        </MainDesign>
    </>
}

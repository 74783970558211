import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {Board, BoardResponse, CreateOrEditBoard} from "../model/tutor/Board";

export interface DuplicateBoardRequest {
    boardId: string;
    targetStudentId: string;
    title?: string;
    description?: string;
}

export class BoardService {
    public static async getAllBoards(studentId: string): Promise<Board[]> {
        return HttpService.sendRequest<Board[]>(
            HttpMethod.GET,
            `/boards/${studentId}`,
        );
    }

    public static async getBoard(boardId: string): Promise<BoardResponse> {
        return HttpService.sendRequest<BoardResponse>(
            HttpMethod.GET,
            `/board/${boardId}`,
        );
    }

    public static async addBoard(board: CreateOrEditBoard): Promise<Board> {
        return HttpService.sendRequest<Board>(
            HttpMethod.POST,
            `/boards`,
            JSON.stringify(board),
        );
    }

    public static async editBoard(board: CreateOrEditBoard): Promise<Board> {
        return HttpService.sendRequest<Board>(
            HttpMethod.PUT,
            `/boards`,
            JSON.stringify(board),
        );
    }

    public static async deleteBoard(id: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.DELETE,
            `/boards/${id}`,
        );
    }

    public static async duplicateBoard(boardId: string, targetStudentId: string, title?: string, description?: string): Promise<Board> {
        return HttpService.sendRequest<Board>(
            HttpMethod.POST,
            `/boards/duplicate`,
            JSON.stringify({
                boardId,
                targetStudentId,
                title,
                description
            } as DuplicateBoardRequest),
        );
    }

    public static async uploadRoomPreview(boardId: string, blob: Blob): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.PUT,
            `/uploadRoomPreview/${boardId}`,
            blob,
            ContentType.IMAGE_PNG,
            "blob"
        );
    }
}
import AnimatedButton from "../../ui/motion/AnimatedButton";
import CustomSelect from "../../ui/form/CustomSelect";
import React, {useState} from "react";
import StudentPreview from "./StudentPreview";
import AddOrEditStudentModal from "./AddOrEditStudentModal";
import StudentShareModal from "./StudentShareModal";
import ConfirmModal from "../../ui/modal/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {useSubmit} from "react-router-dom";
import {Student} from "../../../model/tutor/Student";

interface TutorIndexStudentsSectionProps {
    students: Student[]
    canAddNewStudent: boolean
    guestSession: boolean
}

export default function TutorIndexStudentsSection(props: TutorIndexStudentsSectionProps) {
    const {students, canAddNewStudent, guestSession} = props;

    const {isSubmitting} = useSubmitFormAction("/", null, () => {
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setToggleArchiveModalOpen(false);
    });
    const submit = useSubmit();

    const [studentToEdit, setStudentToEdit] = useState<Student | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [studentToShare, setStudentToShare] = useState<Student | null>(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const [studentToDelete, setStudentToDelete] = useState<Student | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const [studentToToggleArchive, setStudentToToggleArchive] = useState<Student | null>(null);
    const [toggleArchiveModalOpen, setToggleArchiveModalOpen] = useState(false);

    // State for active (non-archived) students
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("newest-activity");

    // State for archived students
    const [archivedSearchTerm, setArchivedSearchTerm] = useState("");
    const [archivedSortOption, setArchivedSortOption] = useState("newest-activity");

    function submitArchiveForm() {
        submit(
            {
                id: studentToToggleArchive!!.id,
                shouldBeArchived: !studentToToggleArchive!!.isArchived
            },
            {
                method: "PATCH",
            }
        );
    }

    function submitDeleteForm() {
        submit(
            {
                id: studentToDelete!!.id,
            },
            {
                method: "DELETE",
            }
        );
    }

    // Active students filtering and sorting
    const filteredActiveStudents = students
        .filter((student) => !student.isArchived)
        .filter((student) => {
            const term = searchTerm.toLowerCase();
            return (
                student.name.toLowerCase().includes(term) ||
                (student.lastName && student.lastName.toLowerCase().includes(term)) ||
                (student.description && student.description.toLowerCase().includes(term))
            );
        });

    const sortedActiveStudents = [...filteredActiveStudents].sort((a, b) => {
        switch (sortOption) {
            case "newest-activity":
                return (
                    new Date(b.lastBoardOpenedAt).getTime() -
                    new Date(a.lastBoardOpenedAt).getTime()
                );
            case "oldest-activity":
                return (
                    new Date(a.lastBoardOpenedAt).getTime() -
                    new Date(b.lastBoardOpenedAt).getTime()
                );
            case "a-z":
                return a.name.localeCompare(b.name, "pl", {
                    sensitivity: "base",
                });
            case "z-a":
                return b.name.localeCompare(a.name, "pl", {
                    sensitivity: "base",
                });
            default:
                return 0;
        }
    });

    // Archived students filtering and sorting
    const allArchivedStudents = students.filter((student) => student.isArchived);
    const hasArchivedStudents = allArchivedStudents.length > 0;

    const filteredArchivedStudents = allArchivedStudents.filter((student) => {
        const term = archivedSearchTerm.toLowerCase();
        return (
            student.name.toLowerCase().includes(term) ||
            (student.lastName && student.lastName.toLowerCase().includes(term)) ||
            (student.description && student.description.toLowerCase().includes(term))
        );
    });

    const sortedArchivedStudents = [...filteredArchivedStudents].sort((a, b) => {
        switch (archivedSortOption) {
            case "newest-activity":
                return (
                    new Date(b.lastBoardOpenedAt).getTime() -
                    new Date(a.lastBoardOpenedAt).getTime()
                );
            case "oldest-activity":
                return (
                    new Date(a.lastBoardOpenedAt).getTime() -
                    new Date(b.lastBoardOpenedAt).getTime()
                );
            case "a-z":
                return a.name.localeCompare(b.name, "pl", {
                    sensitivity: "base",
                });
            case "z-a":
                return b.name.localeCompare(a.name, "pl", {
                    sensitivity: "base",
                });
            default:
                return 0;
        }
    });

    return <>
        <div className="mw-5xl mx-auto">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-4">
                    <AnimatedButton
                        className="btn btn-lg btn-success text-success-light shadow w-100"
                        style={{whiteSpace: "nowrap"}}
                        disabled={!canAddNewStudent}
                        onClick={() => {
                            setStudentToEdit(null);
                            setEditModalOpen(true)
                        }}
                    >
                        Dodaj ucznia
                    </AnimatedButton>
                </div>

                <div className="col-lg-5 col-md-4 mt-2 mt-md-0">
                    <input
                        type="text"
                        className="form-control form-control-lg w-100 p-4 bg-dark text-white"
                        placeholder="Wyszukaj..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className="col-lg-4 col-md-4 mt-2 mt-md-0">
                    <div className="d-inline-block w-100">
                        <CustomSelect
                            value={sortOption}
                            onChange={(e) => setSortOption(e.target.value)}
                            customStyles="form-control form-control-lg w-100 bg-dark text-white p-4"
                        >
                            <option value="newest-activity">
                                Sortuj: Najnowsza aktywność
                            </option>
                            <option value="oldest-activity">
                                Sortuj: Najstarsza aktywność
                            </option>
                            <option value="a-z">
                                Sortuj: Alfabetycznie, A-Z
                            </option>
                            <option value="z-a">
                                Sortuj: Alfabetycznie, Z-A
                            </option>
                        </CustomSelect>
                    </div>
                </div>
            </div>
        </div>
        <hr className="text-white"/>
        <div className="mw-md-5xl mx-auto">
            <div className="mb-20 row g-6">
                {(() => {
                    const totalActiveStudents = students.filter(s => !s.isArchived).length;

                    if (sortedActiveStudents.length === 0 && totalActiveStudents === 0) {
                        // No active students at all
                        return (
                            <div className='mx-3 mt-8'>
                                <h2>Nie masz jeszcze żadnych uczniów.</h2>
                                <p className='text-white fw-medium mb-2'>Dodaj pierwszego ucznia,
                                    klikając w powyższy przycisk.</p>
                                <p className='text-warning fw-medium mt-2'>Aby utworzyć tablicę,
                                    musisz najpierw dodać ucznia.</p>
                            </div>
                        );
                    } else if (sortedActiveStudents.length === 0 && totalActiveStudents > 0) {
                        // There are active students, but none match the search
                        return (
                            <div className='mx-3 mt-8'>
                                <h3 className='text-white fw-medium'>Brak wyników.</h3>
                                <p className='text-white'>Spróbuj zmienić kryteria wyszukiwania.</p>
                            </div>
                        );
                    } else {
                        // Display the filtered and sorted active students
                        return sortedActiveStudents.map((student) => (
                            <React.Fragment key={student.id}>
                                <StudentPreview
                                    key={student.id}
                                    student={student}
                                    setStudentToEdit={setStudentToEdit}
                                    setStudentToDelete={setStudentToDelete}
                                    setStudentToShare={setStudentToShare}
                                    setStudentToToggleArchive={setStudentToToggleArchive}
                                    setShareModalOpen={setShareModalOpen}
                                    setDeleteModalOpen={setDeleteModalOpen}
                                    setEditModalOpen={setEditModalOpen}
                                    setToggleArchiveModalOpen={setToggleArchiveModalOpen}
                                    isActive={true}
                                    guestSession={guestSession}
                                />
                            </React.Fragment>
                        ));
                    }
                })()}
            </div>
        </div>

        {/* Archived students section */}
        {hasArchivedStudents && (
            <>
                <hr className="text-white"/>
                <h2 className='text-center mt-2 mb-4'> Zarchiwizowani uczniowie </h2>

                {/* Row with search and select for ARCHIVED students */}
                <div className="mw-3xl mx-auto mb-4">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 mt-2 mt-md-0">
                            <input
                                type="text"
                                className="form-control form-control-lg w-100 p-4 bg-dark text-white"
                                placeholder="Wyszukaj (zarchiwizowani)..."
                                value={archivedSearchTerm}
                                onChange={(e) => setArchivedSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-2 mt-md-0">
                            <div className="d-inline-block w-100">
                                <CustomSelect
                                    value={archivedSortOption}
                                    onChange={(e) => setArchivedSortOption(e.target.value)}
                                    customStyles="form-control form-control-lg w-100 bg-dark text-white p-4"
                                >
                                    <option value="newest-activity">
                                        Sortuj: Najnowsza aktywność
                                    </option>
                                    <option value="oldest-activity">
                                        Sortuj: Najstarsza aktywność
                                    </option>
                                    <option value="a-z">
                                        Sortuj: Alfabetycznie, A-Z
                                    </option>
                                    <option value="z-a">
                                        Sortuj: Alfabetycznie, Z-A
                                    </option>
                                </CustomSelect>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mw-md-5xl mx-auto">
                    <div className="mb-20 row g-6">
                        {sortedArchivedStudents.length > 0 ? (
                            sortedArchivedStudents.map((student) => (
                                <React.Fragment key={student.id}>
                                    <StudentPreview
                                        key={student.id}
                                        student={student}
                                        setStudentToEdit={setStudentToEdit}
                                        setStudentToDelete={setStudentToDelete}
                                        setStudentToShare={setStudentToShare}
                                        setStudentToToggleArchive={setStudentToToggleArchive}
                                        setShareModalOpen={setShareModalOpen}
                                        setDeleteModalOpen={setDeleteModalOpen}
                                        setEditModalOpen={setEditModalOpen}
                                        setToggleArchiveModalOpen={setToggleArchiveModalOpen}
                                        isActive={false}
                                        guestSession={guestSession}
                                    />
                                </React.Fragment>
                            ))
                        ) : (
                            // If no match found in archived search, show this message but keep search bars visible
                            <div className='mx-3 mt-8'>
                                <h3 className='text-white fw-medium'>Brak wyników.</h3>
                                <p className='text-white'>Spróbuj zmienić kryteria wyszukiwania.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>)
        }

        <AddOrEditStudentModal
            isOpen={editModalOpen}
            setOpen={setEditModalOpen}
            student={studentToEdit!!}
            setStudentToEdit={setStudentToEdit}
            guestSession={guestSession}
        />
        <StudentShareModal
            student={studentToShare!!}
            isOpen={shareModalOpen}
            setOpen={setShareModalOpen}
            setStudentToShare={setStudentToShare}
            guestSession={guestSession}
        />
        <ConfirmModal
            isOpen={toggleArchiveModalOpen}
            setOpen={setToggleArchiveModalOpen}
            title={`Czy na pewno chcesz ${studentToToggleArchive?.isArchived ? 'przywrócić' : 'zarchiwizować'} ucznia '${studentToToggleArchive?.name} ${studentToToggleArchive?.lastName}'?`}
            subtitle={guestSession ? undefined : `W razie potrzeby możesz go ${studentToToggleArchive?.isArchived ? 'zarchiwizować ponownie.' : 'przywrócić. Zarchiwizowani uczniowie wyświetlają się na samym dole strony.'}`}
            subtitleWarn={guestSession ? "W trybie gościa archiwizowanie uczniów nie jest możliwe. Zarejestruj się, aby uzyskać dostęp do wszystkich funkcji." : undefined}
            submitDisabled={guestSession}
            isSubmitting={isSubmitting}
            confirmButtonAction={submitArchiveForm}
        />
        <ConfirmModal
            isOpen={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            title={`Czy na pewno chcesz usunąć ucznia '${studentToDelete?.name} ${studentToDelete?.lastName}'?`}
            subtitle={guestSession ? undefined : "Tej czynności nie będzie dało się cofnąć."}
            subtitleWarn={guestSession ? "W trybie gościa usuwanie uczniów nie jest możliwe. Zarejestruj się, aby uzyskać dostęp do wszystkich funkcji." : undefined}
            submitDisabled={guestSession}
            isSubmitting={isSubmitting}
            confirmButtonAction={submitDeleteForm}
        />
    </>
}
import { approximately, Editor, useIsDarkMode, useValue } from 'tldraw'
import React, { useLayoutEffect, useRef } from 'react'

function drawLine(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    width: number
) {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineWidth = width
    ctx.stroke()
}

export function CustomGrid(
    editor: Editor,
    size: number,
    camera: { x: number; y: number; z: number },
    gridMode: number
) {
    const screenBounds = useValue('screenBounds', () => editor.getViewportScreenBounds(), [])
    const devicePixelRatio = useValue('dpr', () => editor.getInstanceState().devicePixelRatio, [])
    const isDarkMode = useIsDarkMode()

    const canvas = useRef<HTMLCanvasElement>(null)

    useLayoutEffect(() => {
        if (!canvas.current) return

        const canvasW = screenBounds.w * devicePixelRatio
        const canvasH = screenBounds.h * devicePixelRatio

        canvas.current.width = canvasW
        canvas.current.height = canvasH

        const ctx = canvas.current?.getContext('2d')
        if (!ctx) return

        ctx.clearRect(0, 0, canvasW, canvasH)

        // --- Double the cell size dependent on grid mode ---
        const cellSize = gridMode === 2 ? size : size * 2

        const pageViewportBounds = editor.getViewportPageBounds()

        const startPageX = Math.ceil(pageViewportBounds.minX / cellSize) * cellSize
        const startPageY = Math.ceil(pageViewportBounds.minY / cellSize) * cellSize
        const endPageX = Math.floor(pageViewportBounds.maxX / cellSize) * cellSize
        const endPageY = Math.floor(pageViewportBounds.maxY / cellSize) * cellSize

        const numRows = Math.round((endPageY - startPageY) / cellSize)
        const numCols = Math.round((endPageX - startPageX) / cellSize)

        ctx.strokeStyle = isDarkMode ? '#555' : '#BBB'

        for (let row = 0; row <= numRows; row++) {
            const pageY = startPageY + row * cellSize
            // Convert page-space Y to canvas-space
            const canvasY = (pageY + camera.y) * camera.z * devicePixelRatio

            // Check whether it's a major line (every 6 cells) or skip line (every 2 cells)
            const isMajorLine = approximately(pageY % (cellSize * 6), 0)
            const skip = approximately(pageY % (cellSize * 2), 0)

            // Skip the line if `skip` is true (except you'd adjust if you want to show major lines)
            if (!skip) {
                drawLine(ctx, 0, canvasY, canvasW, canvasY, isMajorLine ? 1 : 1)
            }
        }

        for (let col = 0; col <= numCols; col++) {
            const pageX = startPageX + col * cellSize
            // Convert page-space X to canvas-space
            const canvasX = (pageX + camera.x) * camera.z * devicePixelRatio

            const isMajorLine = approximately(pageX % (cellSize * 6), 0)
            const skip = approximately(pageX % (cellSize * 2), 0)

            if (!skip) {
                drawLine(ctx, canvasX, 0, canvasX, canvasH, isMajorLine ? 1 : 1)
            }
        }
    }, [screenBounds, camera, size, devicePixelRatio, editor, isDarkMode])

    return <canvas className="tl-grid" ref={canvas} />
}
import {AssetRecordType, getHashForString, TLAssetStore, TLBookmarkAsset, uniqueId} from "tldraw";
import 'tldraw/tldraw.css'
import React, {Suspense} from "react";
import {Await, defer, Params, useLoaderData} from "react-router-dom";
import MainDesign from "../../components/ui/MainDesign";
import {BoardService} from "../../service/BoardService";
import {BoardResponse} from "../../model/tutor/Board";
import LoadingPage from "./LoadingPage";
import ErrorContent from "../../components/common/ErrorContent";
import CollaborativeBoard from "../../components/common/whiteboard/CollaborativeBoard";
import ReadonlyBoard from "../../components/common/whiteboard/ReadonlyBoard";
import Meta from "../../components/common/Meta";
import {useResize} from "../../hooks/useResize";

const BACKEND_URL = process.env.REACT_APP_API_URL

type LoaderData = {
    boardResponse: Promise<BoardResponse>
}

export default function Whiteboard() {
    const {boardResponse} = useLoaderData() as LoaderData;

    const {handleResize} = useResize()

    return (
        <>
            <MainDesign minHeight="100vh">
                <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                    <Await resolve={boardResponse} errorElement={<ErrorContent/>}>
                        {(boardResponse: BoardResponse) => {
                            return (
                                <>
                                    <Meta
                                        title={boardResponse.board.title}
                                        description={boardResponse.board.description}
                                        imageUrl={boardResponse.board.previewUrl}
                                    />
                                    <div
                                        style={{
                                            height: 'var(--windowInnerHeight, 100vh)',
                                            overflow: 'hidden', // Ensure no scrolling within the board container
                                        }}
                                    >
                                        {boardResponse.readonlySnapshot ? (
                                            <ReadonlyBoard boardResponse={boardResponse} handleResize={handleResize}/>
                                        ) : (
                                            <CollaborativeBoard boardResponse={boardResponse}
                                                                handleResize={handleResize} guestSession={false}/>
                                        )}
                                    </div>
                                </>
                            );
                        }}
                    </Await>
                </Suspense>
            </MainDesign>
        </>
    );
}


export const multiplayerAssets: TLAssetStore = {
    // to upload an asset, we prefix it with a unique id, POST it to our worker, and return the URL
    async upload(_asset, file) {
        const id = uniqueId()

        const roomId = window.location.href.split("/t/")[1]?.split("?")[0]; // Splits the string at "/t/" and ignores any query parameters.
        const objectName = `${id}-${file.name}`
        const url = `${BACKEND_URL}/uploads/${roomId}/${encodeURIComponent(objectName)}`

        const response = await fetch(url, {
            method: 'PUT',
            body: file,
        })

        if (!response.ok) {
            throw new Error(`Failed to upload asset: ${response.statusText}`)
        }

        return {src: url}
    },
    // to retrieve an asset, we can just use the same URL. you could customize this to add extra
    // auth, or to serve optimized versions / sizes of the asset.
    resolve(asset) {
        return asset.props.src
    },
}

// How does our server handle bookmark unfurling?
export async function unfurlBookmarkUrl({url}: { url: string }): Promise<TLBookmarkAsset> {
    const asset: TLBookmarkAsset = {
        id: AssetRecordType.createId(getHashForString(url)),
        typeName: 'asset',
        type: 'bookmark',
        meta: {},
        props: {
            src: url,
            description: '',
            image: '',
            favicon: '',
            title: '',
        },
    }

    try {
        const response = await fetch(`${BACKEND_URL}/unfurl?url=${encodeURIComponent(url)}`)
        const data = await response.json()

        asset.props.description = data?.description ?? ''
        asset.props.image = data?.image ?? ''
        asset.props.favicon = data?.favicon ?? ''
        asset.props.title = data?.title ?? ''
    } catch (e) {
        console.error(e)
    }

    return asset
}

export async function loader({params}: { params: Params<"roomId"> }) {
    const newRoomId = params.roomId;

    document.cookie = `room_id=${newRoomId}; path=/; domain=.${process.env.REACT_APP_FRONTEND_URL_RAW}; max-age=86400; SameSite=None; Secure`;

    return defer({
        boardResponse: await BoardService.getBoard(newRoomId!!)
    });
}

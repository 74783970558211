import {Await, defer, useLoaderData} from "react-router-dom";
import MainDesign from "../ui/MainDesign";
import {CreateOrEditStudent, Student} from "../../model/tutor/Student";
import React, {Suspense} from "react";
import {TutorService} from "../../service/TutorService";
import {ApiError} from "../../service/HttpService";
import LoadingPage from "../../pages/common/LoadingPage";
import {getRawIdTokenAsString} from "../../util/AuthUtil";
import ErrorContent from "../common/ErrorContent";
import {UserService} from "../../service/UserService";
import {User} from "../../model/tutor/User";
import SubscriptionWarning from "./transaction/SubscriptionWarning";
import NumbersItem from "../ui/element/item/NumbersItem";
import AnimatedLink from "../ui/motion/AnimatedLink";
import {MAX_NUMBER_OF_STUDENTS_FREE} from "../../util/UserUtil";
import TutorIndexStudentsSection from "./student/TutorIndexStudentsSection";

interface LoaderData {
    data: {
        students: Promise<Student[]>
        user: Promise<User>
    }
}

export default function TutorIndex() {
    const {data} = useLoaderData() as LoaderData;

    return (
        <>
            <MainDesign containerStyles="pt-10 pb-24 container">
                <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                    <Await resolve={data} errorElement={<ErrorContent/>}>
                        {([students, user]: [Student[], User]) => {
                            const subscriptionExpired = new Date(user.subscriptionUntil).getTime() - new Date().getTime() < 0
                            const subscriptionAboutToExpire = new Date(user.subscriptionUntil).getTime() - new Date().getTime() < 5 * 24 * 60 * 60 * 1000

                            const canAddNewStudent = !subscriptionExpired || user.totalNumberOfStudents < MAX_NUMBER_OF_STUDENTS_FREE

                            return (
                                <>
                                    <div className="text-center mb-4">
                                        <span
                                            className={`badge text-white ${subscriptionExpired ? 'bg-warning' : 'bg-success'} text-uppercase shadow`}>
                                            {subscriptionExpired ? 'Korzystasz z bezpłatnej wersji.' : 'Korzystasz z subskrypcji ❤️'}
                                        </span>
                                    </div>

                                    <h1 className={`text-white text-center ${subscriptionExpired || subscriptionAboutToExpire ? 'mb-4' : 'mb-10 mt-6'}`}>
                                        Witaj, {user.name.trim()}!
                                    </h1>
                                    {
                                        subscriptionExpired && <>
                                            <div className='row g-8 mb-6 mw-md-2xl mx-auto'>
                                                <NumbersItem metricValue={`${user.totalNumberOfStudents ?? 0}/3`}
                                                             metricTitle='Liczba uczniów' smallerText={true}
                                                             tooltipTextAtTheEnd='Możesz mieć maksymalnie 3 uczniów. Usunięcie istniejącego ucznia zwalnia miejsce.'/>
                                                <NumbersItem metricValue={`${user.totalMonthlyBoards ?? 0}/15`}
                                                             metricTitle='Utworzono tablic (miesięcznie)' smallerText={true}
                                                             tooltipTextAtTheEnd='Możesz dodawać maksymalnie 15 tablic miesięcznie. Każda tablica przejdzie w tryb tylko do odczytu po 24h. Usunięcie istniejącej tablicy nie zmniejsza tego limitu. Limit resetuje się z początkiem nowego miesiąca.'/>
                                                <p className='text-white text-center fw-medium fs-11 mt-6'>Limity Cię
                                                    ograniczają? <br className='d-md-none'/><AnimatedLink
                                                        to={'/subskrypcja'}
                                                        className='text-decoration-underline text-success'>Zarządzaj
                                                        subskrypcją</AnimatedLink>.
                                                </p>
                                            </div>
                                        </>
                                    }
                                    {
                                        subscriptionAboutToExpire && <>
                                            <SubscriptionWarning user={user}/>
                                        </>
                                    }

                                    <TutorIndexStudentsSection students={students} canAddNewStudent={canAddNewStudent} guestSession={false}/>

                                </>
                            );
                        }}
                    </Await>
                </Suspense>
            </MainDesign>
        </>
    );
}

export async function loadAllData() {
    return Promise.all([
        TutorService.getAllStudents(),
        UserService.getUser()
    ]);
}

export async function loader({request}: { request: Request }) {
    const token = await getRawIdTokenAsString();
    return defer({
        data: token ? loadAllData() : [],
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    const method = request.method;

    try {
        if (method === "DELETE") {
            const id = formData.get("id") as string;
            await TutorService.deleteStudent(id);

            return {
                status: 200,
                body: "Uczeń został usunięty.",
            };
        }

        if (method === "PATCH") {
            const id = formData.get("id") as string;
            const shouldBeArchived = formData.get("shouldBeArchived") === "true";
            await TutorService.toggleArchive(id, shouldBeArchived);

            return {
                status: 200,
                body: shouldBeArchived ? "Uczeń został pomyślnie zarchiwizowany." : "Uczeń został pomyślnie przywrócony.",
            };
        }

        const requestBody = {
            id: formData.get("id") as string,
            name: formData.get("name") as string,
            lastName: formData.get("lastName") as string,
            description: formData.get("description") as string,
        } as CreateOrEditStudent;

        const requestType = formData.get("requestType") as "CREATE" | "EDIT";

        const isEditing = requestType === "EDIT";
        if (isEditing) {
            await TutorService.editStudent(requestBody);
        } else {
            await TutorService.addStudent(requestBody);
        }

        return {
            status: 200,
            body: isEditing
                ? "Zmiany zostały zapisane."
                : "Uczeń został dodany pomyślnie.",
        };
    } catch (error) {
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd.",
        };
    }
}
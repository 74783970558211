import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {
    Editor,
    STROKE_SIZES,
    TLComponents, Tldraw, TLPageId, TLShape, TLShapeId,
    TLUiActionsContextType,
    TLUiOverrides,
    TLUiTranslationKey,
    useEditor
} from "tldraw";
import {BoardResponse} from "../../model/tutor/Board";
import {CustomMainMenu} from "../common/whiteboard/CustomMainMenu";
import CustomStylePanel from "../common/whiteboard/CustomStylePanel";
import {unfurlBookmarkUrl} from "../../pages/common/Whiteboard";
import {CustomQuickActions} from "../common/whiteboard/CustomQuickActions";
import {CustomGrid} from "../common/whiteboard/CustomGrid";
import AddOrEditBoardModal from "../tutor/board/AddOrEditBoardModal";
import BoardShareModal from "../tutor/board/BoardShareModal";
import BoardInfoModal from "../tutor/board/BoardInfoModal";
import UserSettingsModal from "../common/whiteboard/UserSettingsModal";
import {DEFAULT_STROKE_SIZES} from "../common/whiteboard/CollaborativeBoard";

// TopRightNotification component with responsive positioning
interface TopRightNotificationProps {
    text: string;
}

const TopRightNotification: React.FC<TopRightNotificationProps> = ({ text }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const notificationStyle: React.CSSProperties = {
        position: 'fixed',
        top: isMobile ? '0px' : '10px',
        right: isMobile ? '0px' : '165px',
        background: 'rgba(255, 255, 255, 0.9)',
        color: '#333',
        padding: '10px 15px',
        borderRadius: isMobile ? '0 0 0 8px' : '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        fontWeight: 'bold',
        fontSize: '14px',
        transition: 'right 0.3s ease',
        userSelect: 'none',
        pointerEvents: 'none',
        maxWidth: isMobile ? '200px' : '330px'
    };

    return (
        <div style={notificationStyle}>
            {text}
        </div>
    );
};

interface CollaborativeBoardProps {
    boardResponse: BoardResponse
    handleResize: () => void;
}

// Original:
// s: 2,
// m: 3.5,
// l: 5,
// xl: 10
(STROKE_SIZES as any).s = 1.2;
(STROKE_SIZES as any).m = 2;
(STROKE_SIZES as any).l = 3.5;
(STROKE_SIZES as any).xl = 5;

export default function GuestWhiteboard(props: CollaborativeBoardProps) {
    const {boardResponse, handleResize} = props;

    const [editBoardModalOpen, setEditBoardModalOpen] = React.useState(false);
    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = React.useState(false);

    const [redirectAfterEditUrl, setRedirectAfterEditUrl] = React.useState<string>("/");

    // Use a ref to track the current grid mode
    const currentGridModeRef = useRef<number>(0);

    const editorRef = useRef<Editor | null>(null);
    const [editorReady, setEditorReady] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const latestCameraStateRef = useRef<"idle" | "moving">("idle"); // Keep track of camera movement state

    // Initialize grid mode from URL when component mounts
    useEffect(() => {
        const gridParam = searchParams.get("g");
        if (gridParam) {
            currentGridModeRef.current = parseInt(gridParam);
        }
    }, []);

    // Custom function to handle grid mode cycling
    const toggleGridMode = () => {
        if (!editorRef.current) return;

        // Calculate the next grid mode
        const nextMode = (currentGridModeRef.current + 1) % 3;

        // Update the ref
        currentGridModeRef.current = nextMode;

        // Update the editor's grid state
        editorRef.current.updateInstanceState({
            isGridMode: nextMode !== 0
        });

        // Update URL parameters
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("g", nextMode.toString());
            return newParams;
        }, {replace: true});
    };

    const updateCameraAndPageInUrl = () => {
        if (!editorRef.current) return;

        const cameraState = editorRef.current.getCameraState();

        // Check if the camera state has changed
        if (cameraState === latestCameraStateRef.current) {
            return; // Exit if the state hasn't changed
        }

        if (cameraState === "moving" && latestCameraStateRef.current === "idle") {
            latestCameraStateRef.current = "moving";
            return;
        }

        const camera = editorRef.current.getCamera();
        if (camera.x === 0 && camera.y === 0 && camera.z === 1) {
            return;
        }

        const currentPageId = editorRef.current.getCurrentPageId();

        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("x", camera.x.toFixed(2));
            newParams.set("y", camera.y.toFixed(2));
            newParams.set("z", camera.z.toFixed(2));
            newParams.set("page", currentPageId);

            // Always set the grid mode from our ref
            newParams.set("g", currentGridModeRef.current.toString());

            return newParams;
        }, {replace: true});

        // Update the latest camera state in the ref
        latestCameraStateRef.current = cameraState;
    };

    // Sync grid mode changes from URL to ref and editor
    useEffect(() => {
        if (!editorRef.current || !editorReady) return;

        const gridParam = searchParams.get("g");
        if (gridParam) {
            const gridMode = parseInt(gridParam);

            // Update our ref
            currentGridModeRef.current = gridMode;

            // Update the editor's grid state
            editorRef.current.updateInstanceState({
                isGridMode: gridMode !== 0
            });
        }
    }, [searchParams, editorReady]);

    // Restore Camera and Page on Load
    useEffect(() => {
        if (!editorRef.current || !editorReady) return;

        const x = parseFloat(searchParams.get("x") || "0");
        const y = parseFloat(searchParams.get("y") || "0");
        const z = parseFloat(searchParams.get("z") || "1");
        const gridParam = searchParams.get("g");
        const pageId = searchParams.get("page");

        // Restore the correct page if it exists
        if (pageId) {
            editorRef.current.setCurrentPage(pageId as TLPageId);
        }

        // Set the camera position
        editorRef.current.setCamera({x, y, z});

        // Restore grid mode
        if (gridParam) {
            const gridMode = parseInt(gridParam);
            currentGridModeRef.current = gridMode;
            editorRef.current.updateInstanceState({isGridMode: gridMode !== 0});
            console.log(`Restored grid mode: ${gridMode}, isGridMode: ${gridMode !== 0}`);
        }
    }, [editorReady]);

    // Listen for changes in tick events and update URL
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.on("tick", () => updateCameraAndPageInUrl());
        }
    }, [editorReady]);

    const overrides: TLUiOverrides = {
        actions(_editor, actions): TLUiActionsContextType {
            return {
                ...actions,
                'toggle-grid': {
                    ...actions['toggle-grid' as TLUiTranslationKey],
                    kbd: 'x',
                    onSelect: () => {
                        // Override the default toggle grid behavior with our custom cycle
                        toggleGridMode();
                        return undefined; // Return undefined to prevent default behavior
                    }
                },
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }
        },
    }

    function getComponents(): TLComponents {
        return {
            ActionsMenu: null,
            MainMenu: () => {
                const editor = useEditor();
                return CustomMainMenu(editor, true, boardResponse.board.title, boardResponse.isTutor, "Zakończ lekcję", () => {
                    setEditBoardModalOpen(true)
                    setRedirectAfterEditUrl(boardResponse.board.studentId ? `/demo/uczen/${boardResponse.board.studentId}` : '/')
                });
            },
            QuickActions: () => {
                const editor = useEditor();
                return CustomQuickActions(editor, setShareBoardModalOpen, setBoardInfoModalOpen, setSettingsModalOpen, toggleGridMode, currentGridModeRef.current, true);
            },
            Grid: ({size, ...camera}) => {
                const editor = useEditor();
                return CustomGrid(editor, size, camera, currentGridModeRef.current);
            },
            StylePanel: (props) => {
                return CustomStylePanel(props, true)
            },
        }
    }


    return <>
        <Tldraw
            components={getComponents()}
            onUiEvent={(event, data) => {
                if (event === 'toggle-grid-mode') {
                    toggleGridMode();
                }
            }}
            persistenceKey={boardResponse.board.id}
            options={{maxPages: 100}}
            autoFocus={true}
            inferDarkMode={true}
            overrides={overrides}
            onMount={(editor) => {
                editor.getErasingShapeIds()
                editorRef.current = editor;
                setEditorReady(true);
                // @ts-expect-error
                window.editor = editor
                if (editor.getCurrentPage().name === 'Page 1') {
                    editor.renamePage(editor.getCurrentPage(), "Strona 1")
                }
                // when the editor is ready, we need to register our bookmark unfurling service
                editor.registerExternalAssetHandler('url', unfurlBookmarkUrl)
                // editor.updateInstanceState({isGridMode: false})
                handleResize();

                const isLight = editor.user.getUserPreferences().colorScheme === 'light';
                document.body.style.background = isLight ? '#fefeff' : '#101011';

                editor.on('tick', () => updateCameraAndPageInUrl())

                editor.sideEffects.registerBeforeDeleteHandler('shape', (prev, next) => {
                    if (editor.getCurrentTool().id === 'eraser' && prev.type === 'image') {
                        return false;
                    }
                })

                const originalSetErasingShapes = editor.setErasingShapes.bind(editor);

                editor.setErasingShapes = (shapes) => {
                    // @ts-ignore
                    const filteredShapes = shapes.filter((shape: TLShapeId | TLShape) => {
                        const actualShape = typeof shape === 'string'
                            // @ts-ignore
                            ? editor.getShape(shape)
                            : shape;
                        return actualShape?.type !== 'image';
                    });

                    // @ts-ignore
                    return originalSetErasingShapes(filteredShapes);
                };
            }}
        />

        {/* Add the TopRightNotification component */}
        <TopRightNotification text="Uwaga: Znajdujesz się w trybie testowym. Zmiany nie zostaną zapisane. Aby zapisywać oraz udostępniać tablice, zarejestruj się już teraz." />

        <AddOrEditBoardModal board={boardResponse.board} isOpen={editBoardModalOpen}
                             setOpen={setEditBoardModalOpen}
                             studentId={boardResponse.board.studentId}
                             customTitle='Wprowadź szczegóły zajęć.'
                             redirectAfterEditUrl={redirectAfterEditUrl}
                             guestSession={true}
        />
        <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                         board={boardResponse.board} guestSession={true}/>
        <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                        board={boardResponse.board}
                        isTutor={boardResponse.isTutor}
                        onEditInfoClick={() => {
                            setBoardInfoModalOpen(false);
                            setRedirectAfterEditUrl(`/demo/t/${boardResponse.board.id}`);
                            setEditBoardModalOpen(true);
                        }}/>

        <UserSettingsModal
            isOpen={settingsModalOpen}
            onClose={() => setSettingsModalOpen(false)}
            currentSettings={{
                brushSizes: DEFAULT_STROKE_SIZES,
                gridMode: 0,
                theme: 'dark'
            }}
            onSave={() => {}}
            boardId={boardResponse.board.id}
            guestSession={true}
        />
    </>
}
import {
    DefaultMainMenu,
    Editor,
    KeyboardShortcutsMenuItem,
    TldrawUiMenuGroup,
    TldrawUiMenuItem,
    TldrawUiMenuSubmenu,
    TLPageId
} from "tldraw";
import React, {useCallback} from "react";
import {jsPDF} from "jspdf";
import {useNavigate} from "react-router-dom";

export function CustomMainMenu(
    editor: Editor,
    guestSession: boolean,
    exportFileTitle: string,
    showEndLessonButton: boolean,
    showEndLessonButtonText?: string,
    callOnEndLesson?: () => void,
    onThemeChange?: (newTheme: "dark" | "light") => void
) {
    const navigate = useNavigate();

    const getBackgroundColor = () => {
        return editor.user.getUserPreferences().colorScheme === "light" ? "#fefeff" : "#101011";
    };

    const handleExportCurrentPageAsImage = useCallback(async () => {
        if (!editor) return;

        try {
            const shapeIds = editor.getCurrentPageShapeIds();
            if (shapeIds.size === 0) {
                alert("Tablica jest pusta.");
                return;
            }

            // @ts-ignore
            const { blob } = await editor.toImage([...shapeIds], { background: true, scale: 0.8 });

            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;

            const now = new Date();
            const formattedTime = now.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/:/g, "-");

            a.download = `${exportFileTitle} - ${formattedTime}.png`;
            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error exporting the board:", error);
            alert("Failed to export the board. Please try again.");
        }
    }, [editor]);

    const exportPageAsPDF = async (pageId: TLPageId, pdf: jsPDF, isFirstPage: boolean): Promise<jsPDF> => {
        const backgroundColor = getBackgroundColor();

        try {
            const shapeIds = Array.from(editor.getPageShapeIds(pageId));

            if (shapeIds.length === 0) {
                console.warn(`Page ${pageId} is empty, skipping.`);
                return pdf;
            }

            // @ts-ignore
            const {blob} = await editor.toImage(shapeIds, {background: true, scale: 0.5});

            const img = new Image();
            const url = URL.createObjectURL(blob);

            return new Promise<jsPDF>((resolve) => {
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    if (!ctx) {
                        alert("Błąd generowania PDF.");
                        resolve(pdf);
                        return;
                    }

                    canvas.width = img.width;
                    canvas.height = img.height;

                    ctx.fillStyle = backgroundColor;
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    const compressedImgData = canvas.toDataURL("image/jpeg", 0.7);
                    const orientation = img.width > img.height ? "landscape" : "portrait";

                    if (!isFirstPage) {
                        pdf.addPage(undefined, orientation);
                    }

                    const pageWidth = pdf.internal.pageSize.getWidth();
                    const pageHeight = pdf.internal.pageSize.getHeight();

                    let imgWidth = pageWidth;
                    let imgHeight = (img.height * pageWidth) / img.width;

                    if (imgHeight > pageHeight) {
                        imgHeight = pageHeight;
                        imgWidth = (img.width * pageHeight) / img.height;
                    }

                    pdf.setFillColor(backgroundColor);
                    pdf.rect(0, 0, pageWidth, pageHeight, "F");

                    pdf.addImage(compressedImgData, "JPEG", (pageWidth - imgWidth) / 2, (pageHeight - imgHeight) / 2, imgWidth, imgHeight);

                    URL.revokeObjectURL(url);
                    resolve(pdf);
                };

                img.src = url;
            });
        } catch (error) {
            console.error("Error exporting page:", error);
            return pdf;
        }
    };

    const handleExportAllPagesPDF = useCallback(async () => {
        if (!editor) return;

        const pages = editor.getPages();
        if (pages.length === 0) {
            alert("Brak stron do eksportu.");
            return;
        }

        // Create initial PDF document
        const firstPageId = pages[0].id;
        const firstPageShapes = Array.from(editor.getPageShapeIds(firstPageId));
        const firstPageImage = await editor.toImage(firstPageShapes, {background: true, scale: 0.5});

        const firstImg = new Image();
        const firstUrl = URL.createObjectURL(firstPageImage.blob);

        firstImg.onload = async () => {
            const orientation = firstImg.width > firstImg.height ? "landscape" : "portrait";
            let pdf = new jsPDF({unit: "mm", format: "a4", orientation});

            for (let i = 0; i < pages.length; i++) {
                pdf = await exportPageAsPDF(pages[i].id, pdf, i === 0);
            }

            const now = new Date();
            const formattedTime = now.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/:/g, "-");

            pdf.save(`${exportFileTitle} - Wszystkie strony - ${formattedTime}.pdf`);
        };

        firstImg.src = firstUrl;
    }, [editor]);

    const handleExportCurrentPagePDF = useCallback(async () => {
        if (!editor) return;

        const currentPageId = editor.getCurrentPageId();
        const shapeIds = Array.from(editor.getPageShapeIds(currentPageId));
        const image = await editor.toImage(shapeIds, {background: true, scale: 0.5});

        const img = new Image();
        const url = URL.createObjectURL(image.blob);

        img.onload = async () => {
            const orientation = img.width > img.height ? "landscape" : "portrait";
            let pdf = new jsPDF({unit: "mm", format: "a4", orientation});

            pdf = await exportPageAsPDF(currentPageId, pdf, true);

            const now = new Date();
            const formattedTime = now.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/:/g, "-");

            pdf.save(`${exportFileTitle} - ${formattedTime}.pdf`);
        };

        img.src = url;
    }, [editor]);

    return (
        <DefaultMainMenu>
            {guestSession && (
                <div>
                    {/*@ts-ignore*/}
                    <TldrawUiMenuGroup id="end-lesson">
                        <TldrawUiMenuItem
                            id="register"
                            label="Zarejestruj się"
                            icon="external-link"
                            readonlyOk
                            onSelect={() => {
                                navigate('/rejestracja')
                            }}
                        />
                    </TldrawUiMenuGroup>
                </div>
            )}
            {showEndLessonButton && (
                <div>
                    {/*@ts-ignore*/}
                    <TldrawUiMenuGroup id="end-lesson">
                        <TldrawUiMenuItem
                            id="end-lesson"
                            label={showEndLessonButtonText ?? "Zakończ lekcję"}
                            icon="external-link"
                            readonlyOk
                            onSelect={() => {
                                if (callOnEndLesson) {
                                    callOnEndLesson();
                                }
                            }}
                        />
                    </TldrawUiMenuGroup>
                </div>
            )}
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuSubmenu label={"Eksportuj"} id={'export'}>
                    {/* @ts-ignore*/}
                    <TldrawUiMenuGroup id="export-options">
                        <TldrawUiMenuItem id="export-image" label="Eksportuj PNG" icon="image" readonlyOk
                                          onSelect={handleExportCurrentPageAsImage}/>
                        <TldrawUiMenuItem id="export-pdf" label="Eksportuj PDF (Obecna Strona)" icon="file" readonlyOk
                                          onSelect={handleExportCurrentPagePDF}/>
                        <TldrawUiMenuItem id="export-all-pdf" label="Eksportuj PDF (Wszystkie Strony)" icon="file"
                                          readonlyOk onSelect={handleExportAllPagesPDF}/>
                    </TldrawUiMenuGroup>
                </TldrawUiMenuSubmenu>

            </div>
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="theme-toggle">
                    <TldrawUiMenuItem
                        id="change-theme"
                        label="Zmień motyw"
                        icon="external-link"
                        readonlyOk
                        onSelect={() => {
                            const wasLight = editor.user.getUserPreferences().colorScheme === "light";
                            editor.user.updateUserPreferences({colorScheme: wasLight ? "dark" : "light"});

                            if (onThemeChange) {
                                onThemeChange(wasLight ? "dark" : "light")
                            }

                            document.body.style.background = wasLight ? "#101011" : "#fefeff";
                        }}
                    />
                </TldrawUiMenuGroup>
            </div>
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="shortcuts-menu">
                    <KeyboardShortcutsMenuItem/>
                </TldrawUiMenuGroup>
            </div>
        </DefaultMainMenu>
    )
        ;
}
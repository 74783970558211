import {useSubmit} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {Student} from "../../../model/tutor/Student";
import FormModal from "../../ui/modal/FormModal";
import FormInput from "../../ui/form/FormInput";
import FormTextArea from "../../ui/form/FormTextArea";
import {useToast} from "../../ui/toast/ToastContext";
import {DemoService} from "../../../service/DemoService";
import {v4 as uuidv4} from "uuid";
import {STUDENT_UPDATED_EVENT} from "../../../pages/guest/demo/DemoPage";

interface AddOrEditStudentModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    student?: Student
    setStudentToEdit?: React.Dispatch<React.SetStateAction<Student | null>>
    guestSession: boolean
}

export default function AddOrEditStudentModal(props: AddOrEditStudentModalProps) {
    const {isOpen, setOpen, student, setStudentToEdit, guestSession} = props

    const {showMessage} = useToast()
    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction('/', null, () => {
        setOpen(false);
        setName("");
        setLastName("");
        setDescription("");
    });

    const [name, setName] = useState(student?.name ?? "");
    const [lastName, setLastName] = useState(student?.lastName ?? "");
    const [description, setDescription] = useState(student?.description ?? "");

    useEffect(() => {
        setName(student?.name ?? "");
        setLastName(student?.lastName ?? "")
        setDescription(student?.description ?? "");
    }, [student])

    const isEditing = !!student;

    function submitForm() {
        if (guestSession) {
            if (isEditing) {
                const updatedStudent = {
                    id: student?.id ?? "",
                    name: name,
                    lastName: lastName,
                    description: description,
                    lastBoardOpenedAt: student?.lastBoardOpenedAt ?? new Date().toISOString(),
                    isBoardSharingAllowed: false,
                    isArchived: false
                };

                DemoService.editStudent(updatedStudent);

                // Dispatch custom event to notify that a student was updated
                window.dispatchEvent(new CustomEvent(STUDENT_UPDATED_EVENT));
            } else {
                const newStudent = {
                    id: uuidv4(),
                    name: name,
                    lastName: lastName,
                    description: description,
                    lastBoardOpenedAt: new Date().toISOString(),
                    isBoardSharingAllowed: false,
                    isArchived: false
                } as Student;

                DemoService.addStudent(newStudent);

                // Dispatch custom event for new student too
                window.dispatchEvent(new CustomEvent(STUDENT_UPDATED_EVENT));
            }

            setOpen(false);
            setName("");
            setLastName("");
            setDescription("");
            showMessage("Zapisano pomyślnie.")
        } else {
            submit({
                id: student?.id ?? "",
                name: name,
                lastName: lastName,
                description: description,
                requestType: isEditing ? "EDIT" : "CREATE"
            }, {
                method: "POST",
            });
        }
    }

    return <FormModal isOpen={isOpen} setOpen={setOpen}
                      title={student ? `Edytujesz ucznia ${student.name}` : 'Dodajesz nowego ucznia'}
                      form={
                          <>
                              {
                                  !isEditing &&
                                  <p className='my-0 text-white fw-medium mt-3'>Nie martw się. Będzie można go później
                                      edytować.</p>
                              }
                              <div className={`col-12 col-md-6 ${!isEditing ? 'mt-md-4' : ''}`}>
                                  <FormInput label='Imię ucznia' placeholder='Podaj imię' value={name}
                                             onChange={setName}/>
                              </div>
                              <div className={`col-12 col-md-6 ${!isEditing ? 'mt-md-4' : ''}`}>
                                  <FormInput label='Nazwisko ucznia' placeholder='Podaj nazwisko' value={lastName}
                                             onChange={setLastName}/>
                              </div>
                              <div className='col-12'>
                                  <FormTextArea label='Opis ucznia (klasa, poziom, etc.)' placeholder='Podaj opis'
                                                value={description} onChange={setDescription} rows={3}/>
                              </div>
                          </>
                      }
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                      onClose={() => {
                          if (setStudentToEdit) {
                              setStudentToEdit(null);
                          }
                      }}
    />
}
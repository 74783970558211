import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {BoardResponse} from "../../../model/tutor/Board";
import {useSync} from "@tldraw/sync";
import {multiplayerAssets, unfurlBookmarkUrl} from "../../../pages/common/Whiteboard";
import {
    Editor,
    STROKE_SIZES,
    TLComponents,
    Tldraw,
    TLPageId,
    TLShape,
    TLShapeId,
    TLUiActionsContextType,
    TLUiOverrides,
    TLUiTranslationKey,
    useEditor
} from "tldraw";
import {BoardService} from "../../../service/BoardService";
import {CustomMainMenu} from "./CustomMainMenu";
import {CustomGrid} from "./CustomGrid";
import AddOrEditBoardModal from "../../tutor/board/AddOrEditBoardModal";
import BoardShareModal from "../../tutor/board/BoardShareModal";
import BoardInfoModal from "../../tutor/board/BoardInfoModal";
import CustomStylePanel from "./CustomStylePanel";
import {CustomQuickActions} from "./CustomQuickActions";
import UserSettingsModal from "./UserSettingsModal";
import {UserSettings} from "../../../model/tutor/User";

interface CollaborativeBoardProps {
    boardResponse: BoardResponse
    handleResize: () => void;
    guestSession: boolean;
}

const BACKEND_URL = process.env.REACT_APP_API_URL;

// Original:
// s: 2,
// m: 3.5,
// l: 5,
// xl: 10
export const DEFAULT_STROKE_SIZES = {
    s: 1.2,
    m: 2,
    l: 3.5,
    xl: 5
};

export default function CollaborativeBoard(props: CollaborativeBoardProps) {
    const {boardResponse, handleResize, guestSession} = props;

    const {roomId} = useParams();

    const [userSettings, setUserSettings] = useState<UserSettings>(boardResponse.userSettings);

    // Set stroke sizes based on user settings or fall back to defaults
    useEffect(() => {
        // Check if user settings exist with brush sizes
        const userBrushSizes = userSettings?.brushSizes;

        // For each size, use the user setting if available, otherwise use default
        (STROKE_SIZES as any).s = userBrushSizes?.s ?? DEFAULT_STROKE_SIZES.s;
        (STROKE_SIZES as any).m = userBrushSizes?.m ?? DEFAULT_STROKE_SIZES.m;
        (STROKE_SIZES as any).l = userBrushSizes?.l ?? DEFAULT_STROKE_SIZES.l;
        (STROKE_SIZES as any).xl = userBrushSizes?.xl ?? DEFAULT_STROKE_SIZES.xl;
    }, [userSettings]);
    
    // Update the settings when boardResponse changes
    useEffect(() => {
        if (boardResponse?.userSettings) {
            setUserSettings(boardResponse.userSettings);
        }
    }, [boardResponse]);

    const [editBoardModalOpen, setEditBoardModalOpen] = React.useState(false);
    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = React.useState(false);

    const [redirectAfterEditUrl, setRedirectAfterEditUrl] = React.useState<string>("/");

    // Use a ref to track the current grid mode
    const currentGridModeRef = useRef<number>(0);

    const currentThemeRef = useRef<'dark' | 'light'>(boardResponse.userSettings.theme as 'light' | 'dark' ?? 'dark');

    const editorRef = useRef<Editor | null>(null);
    const [editorReady, setEditorReady] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const store = useSync({
        // We need to know the websocket's URI...
        uri: `${BACKEND_URL}/connect/${roomId}`,

        // ...and how to handle static assets like images & videos
        assets: multiplayerAssets,
    })

    const latestCameraStateRef = useRef<"idle" | "moving">("idle"); // Keep track of camera movement state

    // Initialize grid mode from URL or user settings when component mounts
    useEffect(() => {
        const gridParam = searchParams.get("g");
        if (gridParam) {
            currentGridModeRef.current = parseInt(gridParam);
        } else if (userSettings?.gridMode !== undefined) {
            currentGridModeRef.current = userSettings.gridMode;
        }
    }, []);

    // Custom function to handle grid mode cycling
    const toggleGridMode = () => {
        if (!editorRef.current) return;

        // Calculate the next grid mode
        const nextMode = (currentGridModeRef.current + 1) % 3;

        // Update the ref
        currentGridModeRef.current = nextMode;

        // Update the editor's grid state
        editorRef.current.updateInstanceState({
            isGridMode: nextMode !== 0
        });

        // Update URL parameters
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("g", nextMode.toString());
            return newParams;
        }, { replace: true });
    };

    const updateCameraAndPageInUrl = () => {
        if (!editorRef.current) return;

        const cameraState = editorRef.current.getCameraState();

        // Check if the camera state has changed
        if (cameraState === latestCameraStateRef.current) {
            return; // Exit if the state hasn't changed
        }

        if (cameraState === "moving" && latestCameraStateRef.current === "idle") {
            latestCameraStateRef.current = "moving";
            return;
        }

        const camera = editorRef.current.getCamera();
        if (camera.x === 0 && camera.y === 0 && camera.z === 1) {
            return;
        }

        const currentPageId = editorRef.current.getCurrentPageId();

        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("x", camera.x.toFixed(2));
            newParams.set("y", camera.y.toFixed(2));
            newParams.set("z", camera.z.toFixed(2));
            newParams.set("page", currentPageId);

            // Always set the grid mode from our ref
            newParams.set("g", currentGridModeRef.current.toString());
            newParams.set("t", currentThemeRef.current);

            return newParams;
        }, { replace: true });

        // Update the latest camera state in the ref
        latestCameraStateRef.current = cameraState;
    };

    // Sync grid mode changes from URL to ref and editor
    useEffect(() => {
        if (!editorRef.current || !editorReady) return;

        const gridParam = searchParams.get("g");
        if (gridParam) {
            const gridMode = parseInt(gridParam);

            // Update our ref
            currentGridModeRef.current = gridMode;

            // Update the editor's grid state
            editorRef.current.updateInstanceState({
                isGridMode: gridMode !== 0
            });
        }
    }, [searchParams, editorReady]);

    // Restore Camera and Page on Load
    useEffect(() => {
        if (!editorRef.current || !editorReady) return;

        const x = parseFloat(searchParams.get("x") || "0");
        const y = parseFloat(searchParams.get("y") || "0");
        const z = parseFloat(searchParams.get("z") || "1");
        const gridParam = searchParams.get("g");
        const pageId = searchParams.get("page");
        const theme = searchParams.get("t");

        // Restore the correct page if it exists
        if (pageId) {
            editorRef.current.setCurrentPage(pageId as TLPageId);
        }

        // Set the camera position
        editorRef.current.setCamera({ x, y, z });

        // Restore grid mode
        if (gridParam) {
            const gridMode = parseInt(gridParam);
            currentGridModeRef.current = gridMode;
            editorRef.current.updateInstanceState({ isGridMode: gridMode !== 0 });
        }

        if (theme) {
            editorRef.current.user.updateUserPreferences({colorScheme: theme as "dark" | "light"});
            currentThemeRef.current = theme as "dark" | "light";
        }

    }, [editorReady]);

    // Listen for changes in tick events and update URL
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.on("tick", () => updateCameraAndPageInUrl());
        }
    }, [editorReady]);

    // Upload room-preview
    useEffect(() => {
        let initialTimeoutId: any;
        let intervalId: any;

        if (boardResponse.isTutor && editorReady) {
            const uploadFunction = async () => {
                try {
                    const shapeIds = editorRef.current!!.getCurrentPageShapeIds();
                    if (shapeIds.size === 0) {
                        // No shapes on the canvas
                        return;
                    }
                    // Export the board to a PNG blob
                    // @ts-ignore
                    const {blob} = await editorRef.current!!.toImage([...shapeIds], {
                        background: true,
                        scale: 0.25,
                        darkMode: true
                    })

                    // Check if the blob size is under 2MB (2 * 1024 * 1024 bytes)
                    if (blob.size <= 2 * 1024 * 1024) {
                        const roomId = window.location.href.split("/t/")[1]?.split("?")[0];
                        await BoardService.uploadRoomPreview(roomId, blob);
                    }
                } catch (error) {
                    console.error('Error exporting and uploading board image:', error);
                    console.log(error)
                    try {
                        console.log(JSON.stringify(error));
                    } catch (e) {

                    }
                }
            };

            // Initial delay of 30 seconds
            initialTimeoutId = setTimeout(() => {
                uploadFunction();

                // Then set up interval every 5 * 60 seconds
                intervalId = setInterval(uploadFunction, 5 * 60000);
            }, 30000); // 30000 milliseconds = 30 seconds
        }

        // Cleanup function to clear the interval and timeout
        return () => {
            if (initialTimeoutId) {
                clearTimeout(initialTimeoutId);
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [boardResponse, editorReady]);

    const overrides: TLUiOverrides = {
        actions(_editor, actions): TLUiActionsContextType {
            return {
                ...actions,
                'toggle-grid': {
                    ...actions['toggle-grid' as TLUiTranslationKey],
                    kbd: 'x',
                    onSelect: () => {
                        // Override the default toggle grid behavior with our custom cycle
                        toggleGridMode();
                        return undefined; // Return undefined to prevent default behavior
                    }
                },
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }
        },
    }

    // Handle saving settings from the modal
    const handleSaveSettings = (newSettings: UserSettings, saveAsDefault: boolean) => {
        setUserSettings(newSettings);

        let gridValue: number | undefined = undefined
        let themeValue: string | undefined = undefined

        // Update grid mode from settings
        if (editorRef.current && newSettings.gridMode !== undefined) {
            currentGridModeRef.current = newSettings.gridMode;
            editorRef.current.updateInstanceState({
                isGridMode: newSettings.gridMode !== 0
            });

            gridValue = newSettings.gridMode;
        }

        // Update grid mode from settings
        if (editorRef.current && newSettings.theme !== undefined) {
            currentThemeRef.current = newSettings.theme as "dark" | "light";
            editorRef.current.user.updateUserPreferences({colorScheme: newSettings.theme as "dark" | "light"});

            themeValue = newSettings.theme;
        }

        // Update URL parameters
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            if (gridValue !== undefined) {
                newParams.set("g", gridValue.toString());
            }
            if (themeValue !== undefined) {
                newParams.set("t", themeValue);
            }
            return newParams;
        }, { replace: true });
        
        // If we're updating settings, always save them to the current board
        if (boardResponse.isTutor && boardResponse.board.id) {
            // For "save only for this board" option
            if (!saveAsDefault) {
                // Just update the current board with these settings
                BoardService.editBoard({
                    id: boardResponse.board.id,
                    studentId: boardResponse.board.studentId,
                    title: boardResponse.board.title,
                    description: boardResponse.board.description,
                    settings: newSettings
                }).catch(err => console.error("Error saving board settings:", err));
            } else {
                // For "save as default" option, we need to both update the current board 
                // and also let the UserSettings.updateUserSettings (which was called in the modal) handle the defaults
                BoardService.editBoard({
                    id: boardResponse.board.id,
                    studentId: boardResponse.board.studentId,
                    title: boardResponse.board.title,
                    description: boardResponse.board.description,
                    settings: newSettings
                }).catch(err => console.error("Error saving board settings:", err));
            }
        }
    };

    function getComponents(): TLComponents {
        return {
            ActionsMenu: null,
            MainMenu: () => {
                const editor = useEditor();
                return CustomMainMenu(editor, false, boardResponse.board.title, boardResponse.isTutor, "Zakończ lekcję", () => {
                    setEditBoardModalOpen(true)
                    setRedirectAfterEditUrl(boardResponse.board.studentId ? `/uczen/${boardResponse.board.studentId}` : '/')
                }, (newTheme) => {
                    currentThemeRef.current = newTheme;

                    setSearchParams((prevParams) => {
                        const newParams = new URLSearchParams(prevParams);
                        newParams.set("t", newTheme);
                        return newParams;
                    }, { replace: true });
                });
            },
            QuickActions: () => {
                const editor = useEditor();
                return CustomQuickActions(
                    editor,
                    setShareBoardModalOpen,
                    setBoardInfoModalOpen,
                    setSettingsModalOpen,
                    toggleGridMode,
                    currentGridModeRef.current,
                    boardResponse.isTutor
                );
            },
            Grid: ({size, ...camera}) => {
                const editor = useEditor();
                return CustomGrid(editor, size, camera, currentGridModeRef.current);
            },
            StylePanel: (props) => {
                return CustomStylePanel(props, false)
            },
        }
    }


    return <>
        <Tldraw
            components={getComponents()}
            store={guestSession ? undefined : store}
            onUiEvent={(event) => {
                if (event === 'toggle-grid-mode') {
                    toggleGridMode();
                }
            }}
            persistenceKey={guestSession ? boardResponse.board.id : undefined}
            options={{maxPages: 100}}
            autoFocus={true}
            inferDarkMode={false}
            overrides={overrides}
            onMount={(editor) => {
                editor.getErasingShapeIds()
                editorRef.current = editor;
                setEditorReady(true);
                // @ts-expect-error
                window.editor = editor
                if (editor.getCurrentPage().name === 'Page 1') {
                    editor.renamePage(editor.getCurrentPage(), "Strona 1")
                }
                // when the editor is ready, we need to register our bookmark unfurling service
                editor.registerExternalAssetHandler('url', unfurlBookmarkUrl)

                // Apply grid mode from settings
                if (userSettings?.gridMode !== undefined) {
                    currentGridModeRef.current = userSettings.gridMode;
                    editor.updateInstanceState({
                        isGridMode: userSettings.gridMode !== 0
                    });
                }

                handleResize();

                // Apply theme from settings
                const preferredTheme = userSettings?.theme || 'dark';
                const isLight = preferredTheme === 'light' ||
                    (preferredTheme === 'system' && editor.user.getUserPreferences().colorScheme === 'light');
                document.body.style.background = isLight ? '#fefeff' : '#101011';
                editor.user.updateUserPreferences({colorScheme: isLight ? 'light' : 'dark'})

                editor.on('tick', () => updateCameraAndPageInUrl())

                editor.sideEffects.registerBeforeDeleteHandler('shape', (prev, next) => {
                    if (editor.getCurrentTool().id === 'eraser' && prev.type === 'image') {
                        return false;
                    }
                })

                const originalSetErasingShapes = editor.setErasingShapes.bind(editor);

                editor.setErasingShapes = (shapes) => {
                    // @ts-ignore
                    const filteredShapes = shapes.filter((shape: TLShapeId | TLShape) => {
                        const actualShape = typeof shape === 'string'
                            ? editor.getShape(shape)
                            : shape;
                        return actualShape?.type !== 'image';
                    });
                    // @ts-ignore
                    return originalSetErasingShapes(filteredShapes);
                };
            }}
        />
        <AddOrEditBoardModal board={boardResponse.board} isOpen={editBoardModalOpen}
                             setOpen={setEditBoardModalOpen}
                             studentId={boardResponse.board.studentId}
                             customTitle='Wprowadź szczegóły zajęć.'
                             redirectAfterEditUrl={redirectAfterEditUrl}
                             guestSession={guestSession}
        />
        <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                         board={boardResponse.board} guestSession={guestSession}/>
        <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                        board={boardResponse.board}
                        isTutor={boardResponse.isTutor}
                        onEditInfoClick={() => {
                            setBoardInfoModalOpen(false);
                            setRedirectAfterEditUrl(guestSession ? `/demo/t/${boardResponse.board.id}` : `/t/${boardResponse.board.id}`);
                            setEditBoardModalOpen(true);
                        }}/>
        {/* User Settings Modal */}
        <UserSettingsModal
            isOpen={settingsModalOpen}
            onClose={() => setSettingsModalOpen(false)}
            currentSettings={userSettings}
            onSave={handleSaveSettings}
            boardId={boardResponse.board.id}
            guestSession={false}
        />
    </>
}
import {Student} from "../model/tutor/Student";
import {Board} from "../model/tutor/Board";
import {getCurrentDate} from "../util/DateUtil";

const GUEST_STUDENTS = 'guestStudents';
const GUEST_BOARDS = 'guestBoards';
export const SOURCE_KEY = 'source';
export const FBCLID_KEY = 'fbclid';

const nowInIsoString = new Date().toISOString();

const yesterdayInIsoString = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();

const fiveDaysAgoInIsoString = new Date(new Date().setDate(new Date().getDate() - 5)).toISOString();

const defaultActiveStudent1 = {
    id: '1',
    name: 'Testowy',
    lastName: 'Uczeń',
    description: 'Możesz wprowadzić dowolny opis ucznia.',
    lastBoardOpenedAt: nowInIsoString,
    isBoardSharingAllowed: false,
    isArchived: false
} as Student

const defaultActiveStudent2 = {
    id: '2',
    name: 'Jan',
    lastName: 'Kowalski',
    description: 'Poniedziałek 18:00. I klasa liceum, podstawa z matematyki. 100zł/h.',
    lastBoardOpenedAt: yesterdayInIsoString,
    isBoardSharingAllowed: false,
    isArchived: false
} as Student

const defaultActiveStudent3 = {
    id: '3',
    name: 'Alicja',
    lastName: 'Nowak',
    description: 'Wtorek 18:00, czwartek 17:00. Klasa maturalna, rozszerzenie z matematyki. 120zł/h.',
    lastBoardOpenedAt: fiveDaysAgoInIsoString,
    isBoardSharingAllowed: false,
    isArchived: false
} as Student

const defaultBoard1 = {
    id: '1',
    title: `Tablica z dnia: ${getCurrentDate()}`,
    description: 'Dodaj swój opis zajęć',
    lastOpenedAt: new Date().toISOString(),
    createdAt: yesterdayInIsoString,
    studentId: '1',
    previewUrl: 'https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/tnk-demo-default.png',
} as Board

export class DemoService {
    public static createInitialData(): void {
        if (!localStorage.getItem(GUEST_STUDENTS) && !localStorage.getItem(GUEST_BOARDS)) {
            localStorage.setItem(GUEST_STUDENTS, JSON.stringify([
                defaultActiveStudent1,
                defaultActiveStudent2,
                defaultActiveStudent3
            ]));
            localStorage.setItem(GUEST_BOARDS, JSON.stringify([defaultBoard1]));
        }
    }

    public static getBoards(): Board[] {
        return JSON.parse(localStorage.getItem(GUEST_BOARDS) || '[]') as Board[];
    }

    public static getBoardById(id: string): Board {
        return this.getBoards().find(b => b.id === id)!!;
    }

    public static getStudents(): Student[] {
        return JSON.parse(localStorage.getItem(GUEST_STUDENTS) || '[]') as Student[];
    }

    public static getStudentById(id: string): Student {
        return this.getStudents().find(s => s.id === id)!!;
    }

    public static getBoardsForStudent(id: string): Board[] {
        return this.getBoards().filter(b => b.studentId === id);
    }

    public static storeInitialSource(source: string) {
        localStorage.setItem(SOURCE_KEY, source);
    }

    public static storeInitialFbclid(source: string) {
        localStorage.setItem(FBCLID_KEY, source);
    }

    public static addStudent(student: Student): void {
        const students = this.getStudents();
        students.push(student);
        localStorage.setItem(GUEST_STUDENTS, JSON.stringify(students));
    }

    public static addBoard(board: Board): void {
        const boards = this.getBoards();
        boards.push(board);
        localStorage.setItem(GUEST_BOARDS, JSON.stringify(boards));
    }

    public static editStudent(student: Student): void {
        const students = this.getStudents();
        const studentIndex = students.findIndex(s => s.id === student.id);
        students[studentIndex] = student;
        localStorage.setItem(GUEST_STUDENTS, JSON.stringify(students));
    }

    public static editBoard(board: Board): void {
        const boards = this.getBoards();
        const boardIndex = boards.findIndex(b => b.id === board.id);
        boards[boardIndex] = board;
        localStorage.setItem(GUEST_BOARDS, JSON.stringify(boards));
    }

    public static clearEverything(): void {
        localStorage.removeItem(GUEST_STUDENTS);
        localStorage.removeItem(GUEST_BOARDS);
        localStorage.removeItem(SOURCE_KEY);
        localStorage.removeItem(FBCLID_KEY);
    }
}
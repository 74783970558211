import React, {useEffect, useState} from 'react';
import {UserSettings} from "../../../model/tutor/User";
import {UserService} from "../../../service/UserService";
import RawModal from "../../ui/modal/RawModal";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AnimatedLink from "../../ui/motion/AnimatedLink";

interface UserSettingsModalProps {
    isOpen: boolean;
    onClose: () => void;
    currentSettings: UserSettings;
    onSave: (settings: UserSettings, saveAsDefault: boolean) => void;
    boardId: string;
    guestSession: boolean;
}

export default function UserSettingsModal({
                                              isOpen,
                                              onClose,
                                              currentSettings,
                                              onSave,
                                              boardId,
                                              guestSession
                                          }: UserSettingsModalProps) {
    const [settings, setSettings] = useState<UserSettings>(currentSettings);
    const [isSaving, setIsSaving] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);

    // Store brush size inputs as strings to allow for empty input temporarily
    const [brushSizeInputs, setBrushSizeInputs] = useState({
        s: currentSettings.brushSizes.s.toString(),
        m: currentSettings.brushSizes.m.toString(),
        l: currentSettings.brushSizes.l.toString(),
        xl: currentSettings.brushSizes.xl.toString()
    });

    useEffect(() => {
        setSettings(currentSettings);
        setBrushSizeInputs({
            s: currentSettings.brushSizes.s.toString(),
            m: currentSettings.brushSizes.m.toString(),
            l: currentSettings.brushSizes.l.toString(),
            xl: currentSettings.brushSizes.xl.toString()
        });
        setValidationError(null);
        setAttemptedSubmit(false);
    }, [currentSettings]);

    const validateSettings = (): boolean => {
        // Parse each brush size from the input strings
        const s = parseFloat(brushSizeInputs.s);
        const m = parseFloat(brushSizeInputs.m);
        const l = parseFloat(brushSizeInputs.l);
        const xl = parseFloat(brushSizeInputs.xl);

        // Check if any brush size is NaN or empty
        if (isNaN(s) || isNaN(m) || isNaN(l) || isNaN(xl) ||
            brushSizeInputs.s.trim() === '' ||
            brushSizeInputs.m.trim() === '' ||
            brushSizeInputs.l.trim() === '' ||
            brushSizeInputs.xl.trim() === '') {
            setValidationError("Wszystkie rozmiary pędzla muszą być poprawnymi liczbami.");
            return false;
        }

        // Check for minimum value (0.1)
        if (s < 0.1 || m < 0.1 || l < 0.1 || xl < 0.1) {
            setValidationError("Rozmiary pędzla nie mogą być mniejsze niż 0.1.");
            return false;
        }

        // Update the actual settings with the validated numbers
        setSettings(prev => ({
            ...prev,
            brushSizes: {
                s,
                m,
                l,
                xl
            }
        }));

        setValidationError(null);
        return true;
    };

    const handleBrushSizeChange = (size: "s" | "m" | "l" | "xl", value: string) => {
        // Store the raw input string - clean any negative signs at the beginning
        const cleanedValue = value.replace(/^-/, '');

        setBrushSizeInputs(prev => ({
            ...prev,
            [size]: cleanedValue
        }));

        // If it's a valid number, update the settings
        const numValue = parseFloat(cleanedValue);
        if (!isNaN(numValue) && cleanedValue.trim() !== '') {
            // Ensure the value is at least 0.1
            const validatedValue = Math.max(0.1, numValue);

            setSettings(prev => ({
                ...prev,
                brushSizes: {
                    ...prev.brushSizes,
                    [size]: validatedValue
                }
            }));
        }
    };

    const handleGridModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings({
            ...settings,
            gridMode: parseInt(e.target.value)
        });
    };

    const handleThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings({
            ...settings,
            theme: e.target.value
        });
    };

    const handleSaveDefault = async () => {
        setAttemptedSubmit(true);
        if (!validateSettings()) return;

        setIsSaving(true);
        try {
            // Save to user settings (affects all boards)
            await UserService.updateUserSettings(settings);
            onSave(settings, true);
            onClose();
        } catch (error) {
            console.error("Error saving default settings:", error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleSaveCurrentBoard = async () => {
        setAttemptedSubmit(true);
        if (!validateSettings()) return;

        setIsSaving(true);
        try {
            // Save only for current board
            onSave(settings, false);
            onClose();
        } catch (error) {
            console.error("Error saving board settings:", error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleReset = async () => {
        try {
            const defaultSettings = await UserService.getDefaultUserSettings();
            setSettings(defaultSettings);
            setBrushSizeInputs({
                s: defaultSettings.brushSizes.s.toString(),
                m: defaultSettings.brushSizes.m.toString(),
                l: defaultSettings.brushSizes.l.toString(),
                xl: defaultSettings.brushSizes.xl.toString()
            });
            setValidationError(null);
            setAttemptedSubmit(false);
        } catch (error) {
            console.error("Error getting default settings:", error);
        }
    };

    return (
        <RawModal
            isOpen={isOpen}
            setOpen={() => onClose()}
            title="Ustawienia tablicy"
            onClose={onClose}
        >
            <hr className='text-white'/>
            <p className="text-white fw-medium text-secondary-light fs-9 mb-4">
                Dostosuj domyślne ustawienia pędzla, siatki i wyglądu tablicy.
            </p>

            {attemptedSubmit && validationError && (
                <div className="alert alert-danger mb-3" role="alert">
                    {validationError}
                </div>
            )}

            <div className="mb-4">
                <h6 className="fw-medium mb-3 text-white">Rozmiary pędzla</h6>
                <div className="row g-3">
                    <div className="col-6">
                        <label htmlFor="brush-size-s" className="form-label text-secondary-light">Mały (S)</label>
                        <input
                            id="brush-size-s"
                            className="form-control bg-light-dark text-white border-secondary"
                            type="number"
                            step="0.1"
                            min="0.1"
                            max="10"
                            value={brushSizeInputs.s}
                            onChange={(e) => handleBrushSizeChange("s", e.target.value)}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="brush-size-m" className="form-label text-secondary-light">Średni (M)</label>
                        <input
                            id="brush-size-m"
                            className="form-control bg-light-dark text-white border-secondary"
                            type="number"
                            step="0.1"
                            min="0.5"
                            max="10"
                            value={brushSizeInputs.m}
                            onChange={(e) => handleBrushSizeChange("m", e.target.value)}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="brush-size-l" className="form-label text-secondary-light">Duży (L)</label>
                        <input
                            id="brush-size-l"
                            className="form-control bg-light-dark text-white border-secondary"
                            type="number"
                            step="0.1"
                            min="0.5"
                            max="10"
                            value={brushSizeInputs.l}
                            onChange={(e) => handleBrushSizeChange("l", e.target.value)}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="brush-size-xl" className="form-label text-secondary-light">Bardzo duży
                            (XL)</label>
                        <input
                            id="brush-size-xl"
                            className="form-control bg-light-dark text-white border-secondary"
                            type="number"
                            step="0.1"
                            min="0.5"
                            max="20"
                            value={brushSizeInputs.xl}
                            onChange={(e) => handleBrushSizeChange("xl", e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <label htmlFor="grid-mode" className="form-label text-secondary-light">Tryb siatki</label>
                <select
                    id="grid-mode"
                    className="form-select bg-light-dark text-white border-secondary"
                    value={settings.gridMode.toString()}
                    onChange={handleGridModeChange}
                >
                    <option value="0">Brak siatki</option>
                    <option value="1">Standardowa siatka</option>
                    <option value="2">Mała siatka</option>
                </select>
            </div>

            <div className="mb-2">
                <label htmlFor="theme" className="form-label text-secondary-light">Motyw</label>
                <select
                    id="theme"
                    className="form-select bg-light-dark text-white border-secondary"
                    value={settings.theme}
                    onChange={handleThemeChange}
                >
                    <option value="dark">Ciemny</option>
                    <option value="light">Jasny</option>
                </select>
            </div>

            <div className="mt-0">
                {
                    !guestSession &&
                    <p className='text-warning fw-medium fs-11'>Uwaga: Po zapisaniu ustawień, każdy z uczestników
                        tablicy powinien odświeżyć stronę, aby zobaczyć zmiany.</p>
                }

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <AnimatedButton
                        className="btn btn-outline-light btn-sm"
                        onClick={handleReset}
                        type="button"
                    >
                        <i className="fas fa-undo me-1"></i> Przywróć domyślne
                    </AnimatedButton>

                    <AnimatedButton
                        className="btn btn-outline-light btn-sm"
                        onClick={onClose}
                        type="button"
                    >
                        Anuluj
                    </AnimatedButton>
                </div>

                {
                    guestSession && <>
                        <p className='text-center fw-medium text-warning fs-11'>W trybie testowym nie możesz edytować
                            ustawień. <br/>Zarejestruj się, aby korzystać ze wszystkich funkcji.</p>
                        <div className="d-flex justify-content-center gap-2 mt-4">
                            <AnimatedLink
                                className="btn btn-lg btn-success text-success-light"
                                onClick={handleSaveDefault}
                                to="/rejestracja"
                            >
                                Zarejestruj się
                            </AnimatedLink>
                        </div>
                    </>
                }
                {
                    !guestSession && <>
                        <div className="d-flex justify-content-center gap-2 mt-4">
                            <AnimatedButton
                                className="btn btn-lg btn-primary text-white"
                                onClick={handleSaveCurrentBoard}
                                type="button"
                                disabled={isSaving}
                                style={{width: 'auto'}}
                            >
                                {isSaving ? "Zapisywanie..." : "Zapisz tylko dla tej tablicy"}
                            </AnimatedButton>

                            <AnimatedButton
                                className="btn btn-lg btn-success text-success-light"
                                onClick={handleSaveDefault}
                                type="button"
                                disabled={isSaving}
                                style={{width: 'auto'}}
                            >
                                {isSaving ? "Zapisywanie..." : "Zapisz jako domyślne"}
                            </AnimatedButton>
                        </div>
                    </>
                }

            </div>
        </RawModal>
    );
}
import AnimatedLink from "../../ui/motion/AnimatedLink";
import RightArrow from "../../ui/element/RightArrow";
import DropdownMenu from "../../ui/element/DropdownMenu";
import React from "react";
import {Board} from "../../../model/tutor/Board";
import {Link} from "react-router-dom";

interface BoardPreviewProps {
    board: Board
    readonly: boolean
    setBoardToEdit: React.Dispatch<React.SetStateAction<Board | null>>
    setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setBoardToShare: React.Dispatch<React.SetStateAction<Board | null>>
    setShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setBoardToDelete: React.Dispatch<React.SetStateAction<Board | null>>
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setBoardToDuplicate?: React.Dispatch<React.SetStateAction<Board | null>>
    setDuplicateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
    guestSessions: boolean
}

export default function BoardPreview(props: BoardPreviewProps) {
    const {
        board,
        readonly,
        setBoardToEdit,
        setEditModalOpen,
        setBoardToShare,
        setShareModalOpen,
        setBoardToDelete,
        setDeleteModalOpen,
        setBoardToDuplicate,
        setDuplicateModalOpen,
        guestSessions
    } = props
    const boardLink = guestSessions ? `/demo/t/${board.id}` : `/t/${board.id}`;

    function standby() {
        // @ts-ignore
        document.getElementById(board.id).src = 'https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/default.png'
    }

    return <>
        <div className='col-12 col-sm-6 col-md-4'>
            <div>
                <div className='mb-6 w-100 position-relative'
                     style={{overflow: 'hidden', paddingTop: '56.25%'}}>
                    <Link to={boardLink}>
                        <img id={board.id} className='lazyload' data-src={board.previewUrl} alt={'Podgląd tablicy'} onError={standby}
                             style={{
                                 position: 'absolute',
                                 top: 0,
                                 left: 0,
                                 width: '100%',
                                 height: '100%',
                                 objectFit: 'cover'
                             }}/>
                    </Link>

                </div>
            </div>

            <AnimatedLink to={boardLink} className='d-inline-block'>
                <h3 className='font-heading mb-4 fs-8 link-white text-white'>
                    {board.title}
                </h3>
            </AnimatedLink>
            <p className='mb-2 text-secondary-light fs-10 fw-medium'>
                {board.description}
            </p>
            <p className='mb-2 text-secondary-light fw-medium fs-11'>
                Ostatnie otwarcie: {board.lastOpenedAt.split('T')[0]}
            </p>
            <p className='mb-2 text-secondary-light fw-medium fs-12'>
                Utworzono: {board.createdAt?.split('T')[0]}
            </p>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <AnimatedLink
                    className='d-flex align-items-center text-success link-success'
                    to={boardLink}
                >
                    <span className='me-2 fw-semibold fs-10'>Zobacz więcej</span>
                    <RightArrow/>
                </AnimatedLink>
                {
                    !readonly && <DropdownMenu elements={[
                        {
                            name: 'Edytuj',
                            onClick: () => {
                                setBoardToEdit(board)
                                setEditModalOpen(true)
                            }
                        },
                        {
                            name: 'Udostępnij',
                            onClick: () => {
                                setBoardToShare(board);
                                setShareModalOpen(true);
                            }
                        },
                        ...(setBoardToDuplicate && setDuplicateModalOpen ? [{
                            name: 'Duplikuj',
                            onClick: () => {
                                setBoardToDuplicate(board);
                                setDuplicateModalOpen(true);
                            }
                        }] : []),
                        {
                            name: 'Usuń',
                            onClick: () => {
                                setBoardToDelete(board)
                                setDeleteModalOpen(true)
                            }
                        }
                    ]}/>
                }
            </div>
        </div>
    </>
}
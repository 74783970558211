import {format, parseISO} from "date-fns";

export function getCurrentDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export function formatISODateString(dateString: string) {
    try {
        const date = parseISO(dateString);
        return format(date, 'dd.MM.yyyy');
    } catch (error) {
        return "";
    }
}

export function formatISODateTimeString(dateString: string) {
    try {
        const date = parseISO(dateString);
        return format(date, 'dd.MM.yyyy HH:mm');
    } catch (error) {
        return "";
    }
}